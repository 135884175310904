import { Link } from "react-router-dom";
import Zobo from '../../Assets/Images/zobo.png';
import '../../Assets/Css/Components/Components/Navbar.css'
import { BiChevronUp, BiLogoDiscordAlt, BiMenu, BiX } from "react-icons/bi"
import { HiLogout } from "react-icons/hi";
import { FaServer } from "react-icons/fa6";
import { HiMenu } from "react-icons/hi";
import { useEffect, useState } from "react";

const Navbar = ({user, api, handleLogout, handleLogin, handleCallback}) => {

  const [open, setOpen] = useState(false)
  const [userOpen, setUserOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const userDrop = document.querySelector('.userDrop');
    const topPoint = document.querySelector('.navbarApp .cnt');
    const bottomPoint = document.querySelector('.navbarApp .rgt ul.links');
  
    if (userDrop && topPoint && bottomPoint) {
      if (windowWidth < 800) {
        topPoint.appendChild(userDrop);
      } else {
        bottomPoint.appendChild(userDrop);
      }
    }
  }, [windowWidth]);



    return ( 
      <div className={`navbarApp${open ? " open" : ""}`}>
          <div className="lft sd">
            <ul className='links'>
              <li onClick={() => setOpen(false)} className={window.location.pathname==="/"?"active":""}><Link to="/">Accueil</Link></li>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/commands')?"active":""}><Link to="/commands">Commandes</Link></li>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/bumps')?"active":""}><Link to="/bumps">Bumps</Link></li>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/codes')?"active":""}><Link to="/codes">Codes</Link></li>
           </ul>
          </div>
          <div className="cnt">
            <div className="resBtn" onClick={() => {
              setOpen(!open)
            }}>
              {open ?
              <BiX />
              :
              <BiMenu />
              }
            </div>
            <img src={Zobo} alt="Zobo" />
          </div>
          <div className="rgt sd">
            <ul className='links'>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/votes')?"active":""}><Link to="/votes">Votes</Link></li>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/leaderboard')?"active":""}><Link to="/leaderboard">Classement</Link></li>
              <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/partners')?"active":""}><Link to="/partners">Partenaires</Link></li>
             {// <li onClick={() => setOpen(false)} className={window.location.pathname.startsWith('/status')?"active":""}><Link to="/status">Status</Link></li>
             }
              {
                user.authenticated ? 
                <div className={`userDrop${userOpen ? " open" : ""}`}>
                <li className={`login connected${userOpen ? " open" : ""}`} onClick={() => {
             setUserOpen(!userOpen)
           }}><Link>
             <img className="avatar" src={user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : `https://cdn.discordapp.com/embed/avatars/0.png`} alt={user.username} />
             <span>{user.username.length > 20 ? user.username.slice(0, 17)+"..." : user.username}</span>
             <BiChevronUp/>
             </Link>
           
             </li>
               <div className={`drop`}>
               <ul className="options">
                 <Link to="/dashboard" className={window.location.pathname.startsWith('/dashboard')?"active":""}>
                   <FaServer/> Dashboard
                 </Link>
                 <div className="sep"></div>
                 <Link onClick={() => handleLogout()}>
                   <HiLogout/> Se déconnecter
                 </Link>
               </ul>
             </div>
           </div>
                :
                <li className="login" onClick={() => handleLogin()} ><Link >Connexion</Link></li>
              }
           </ul>
        
          </div>
        </div>
     );
}
 
export default Navbar;