module.exports = [
    {
        name: "Partner 1",
        order: 4,
        links: [{name: "Lien 1", url: ""}, {name: "Lien 2", url: ""}],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie augue augue, in finibus mi rhoncus eu. Integer facilisis lorem at sapien cursus bibendum. Aliquam dignissim elit eget orci elementum semper. Nam mollis, dolor eu aliquam vehicula, velit tellus faucibus neque, in rhoncus lorem felis in nisi. Nulla vulputate justo non laoreet tristique. In malesuada dapibus eleifend. Sed accumsan sit amet lacus in lobortis."
    },
    {
        name: "Partner 2",
        order: 2,
        links: [{name: "Lien 1", url: ""}, {name: "Lien 2", url: ""}],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie augue augue, in finibus mi rhoncus eu. Integer facilisis lorem at sapien cursus bibendum. Aliquam dignissim elit eget orci elementum semper. Nam mollis, dolor eu aliquam vehicula, velit tellus faucibus neque, in rhoncus lorem felis in nisi. Nulla vulputate justo non laoreet tristique. In malesuada dapibus eleifend. Sed accumsan sit amet lacus in lobortis."
    },
    {
        name: "Partner 3",
        order: 3,
        links: [{name: "Lien 1", url: ""}, {name: "Lien 2", url: ""}],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie augue augue, in finibus mi rhoncus eu. Integer facilisis lorem at sapien cursus bibendum. Aliquam dignissim elit eget orci elementum semper. Nam mollis, dolor eu aliquam vehicula, velit tellus faucibus neque, in rhoncus lorem felis in nisi. Nulla vulputate justo non laoreet tristique. In malesuada dapibus eleifend. Sed accumsan sit amet lacus in lobortis."
    },
    {
        name: "Partner 4",
        order: 1,
        links: [{name: "Lien 1", url: ""}, {name: "Lien 2", url: ""}],
        description: "Lorem ipsum 4 dolor sit amet, consectetur adipiscing elit. Phasellus molestie augue augue, in finibus mi rhoncus eu. Integer facilisis lorem at sapien cursus bibendum. Aliquam dignissim elit eget orci elementum semper. Nam mollis, dolor eu aliquam vehicula, velit tellus faucibus neque, in rhoncus lorem felis in nisi. Nulla vulputate justo non laoreet tristique. In malesuada dapibus eleifend. Sed accumsan sit amet lacus in lobortis."
    }
]