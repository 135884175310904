import { Link } from "react-router-dom";
import Zobo from '../../../../Assets/Images/zobo.png';
import '../../../../Assets/Css/Components/Dash/Components/DashNav.css'
import { HiMenuAlt2 } from "react-icons/hi";
import { BiChevronUp, BiLogoDiscordAlt, BiMenu, BiX } from "react-icons/bi"
import { HiLogout } from "react-icons/hi";
import { FaServer } from "react-icons/fa6";
import { HiMenu } from "react-icons/hi";
import { useEffect, useState } from "react";

const DashNavbar = ({user, handleLogout, handleLogin, open, setOpen}) => {

  
  const [userOpen, setUserOpen] = useState(false)


    return ( 
      <div className={`navbarDashApp${open ? " open" : ""}`}>
          <div className="lft sd">
          <div className="resBtn" onClick={() => {
              setOpen(!open)
            }}>
              {open ?
              <HiMenuAlt2 />
              :
              <HiMenu  />
              }
            </div>
          </div>
          <div className="cnt">
           <Link to="/">
            <img src={Zobo} alt="Zobo" />
           </Link>
          </div>
          <div className="rgt sd">
            <ul className='links'>
              {
                user.authenticated ? 
                <div className={`userDrop${userOpen ? " open" : ""}`}>
                <li className={`login connected${userOpen ? " open" : ""}`} onClick={() => {
             setUserOpen(!userOpen)
           }}><Link>
             <img className="avatar" src={user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : `https://cdn.discordapp.com/embed/avatars/0.png`} alt={user.username} />
             <span>{user.username.length > 20 ? user.username.slice(0, 17)+"..." : user.username}</span>
             <BiChevronUp/>
             </Link>
           
             </li>
               <div className={`drop`}>
               <ul className="options">
                 <Link to="/dashboard" className={window.location.pathname.startsWith('/dashboard')?"active":""}>
                   <FaServer/> Dashboard
                 </Link>
                 <div className="sep"></div>
                 <Link onClick={() => handleLogout()}>
                   <HiLogout/> Se déconnecter
                 </Link>
               </ul>
             </div>
           </div>
                :
                <li className="login" onClick={() => handleLogin()} ><Link >Connexion</Link></li>
              }
           </ul>
        
          </div>
        </div>
     );
}
 
export default DashNavbar;