import { BiChevronDown, BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { BsSortDown, BsSortNumericDown, BsSortNumericUp, BsSortUp } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import '../../Assets/Css/Components/Ranking.css'
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";

const   Ranking = ({api}) => {

    const [sortOpen, setSortOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [sort, setSort] = useState('a')
    const [users, setUsers] = useState([])

    async function loadData() {
        let res = await axios(`${api}/users`)
        // console.log(res.data)
        if(!res.data.data) return;

        setUsers(res.data.data.sort((a, b) => b.data.votesCount - a.data.votesCount))
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    
    const sortFunctions = {
        a: ((a, b) => b.data.votesCount - a.data.votesCount),
        b: ((a, b) => a.data.votesCount - b.data.votesCount)
    }

    const sortOptions = [
        { value: "a", label: <><BsSortNumericUp/> Le + de votes</> },
        { value: "b", label: <><BsSortNumericDown/> Le - de votes</> },
    ];




    function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        let newServArr = []
        users.forEach(srv => {
            let servName = srv.name.replace(/[\s-]/g, '');
            if(!searchKey) {
                srv.hided = false;
            } else {
                if(servName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
                srv.hided = false
            } else {
                srv.hided = true
            }
            }
            newServArr.push(srv)
            setUsers(newServArr)
        })
      
    }

    return ( 
       <div className="bumpsApp">
        <div className="searchApp">
            <div className="searchBar">
                <input id="searchByName" spellCheck={false} type="text" onChange={(e) => { handleSearchBar(e.target)}} />
                <div className="srcBtn">
                <BiSearch/>
                </div>
            </div>
            <div className={`sortMenu${sortOpen?" open":""}`}>
                <div className="label" onClick={()=>setSortOpen(!sortOpen)}>
                <div className="txt">
                {sortOptions.find(o => o.value===sort).label}
                </div>
                <div className="btn">
                <BiChevronUp/>
                </div>
                </div>
                <div className="drop">
                    <ul> 
                        {sortOptions.map(o => {
                            return (
                                <li key={o.value} className={o.value === sort ? "active":""} onClick={() => {
                                    setSort(o.value)
                                    setSortOpen(!sortOpen)
                                    }}>
                                {o.label}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        <div className="usersList">

            {
                loading ?
                <div className="loadingApp bumps">
                    <BiLoaderAlt />
                </div>
                :
                users.sort(sortFunctions[sort]).map((us, ind) => {
                    if(us.hided) return;
                    return (
                     <div className="user">
                        <div className="rank">
                            #{ind+1}
                        </div>
                        <div className="icon">
                            {
                                us.avatarLink ?
                                <img src={us.avatarLink} alt="" />
                                :
                                null
                            }
                        </div>
                        <div className="txts">
                            <div className="name">
                                {us.name}
                            </div>
                            <div className="votesCount">
                                <span>{us.data.votesCount  || 0}</span> vote{us.data.votesCount > 1 ?"s":null}
                            </div>
                        </div>
                     </div>
                    )
                })
            }

        </div>
       </div>
     );
}
 
export default  Ranking;