import { Link, useParams } from 'react-router-dom'

const ServersSelect =  ({api, user, id, guilds}) => {

    return (
        <div className="serversSelect">
        <div className={`srvr${!id || id === "admin" ? ' active':''}`}>
                 <Link to={`/dashboard`}>
                  <div className="icon">
                  <img className="avatar" src={user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : `https://cdn.discordapp.com/embed/avatars/0.png`} alt={user.username} />
                  </div>
                   
                 </Link>
              </div>
             <div className="srvr">
             <div className="sep"></div>
             </div>
          {guilds.map(g => {
            return (
              <div className={`srvr${id === g.id ? ' active':''}`} key={g.id}>
                 <Link to={`/dashboard/${g.id}`}>
                  <div className="icon">
                     { g.icon ?
                         <img src={`https://cdn.discordapp.com/icons/${g.id}/${g.icon}.png`} alt="" />
                         :
                         <span>{g.name.slice(0, 1)}</span>
                    }
                  </div>
                   
                 </Link>
              </div>
            )
          })}
        </div>
    )
}

export default ServersSelect