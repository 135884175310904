import { BiChevronDown, BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { BsSortDown, BsSortNumericDown, BsSortNumericUp, BsSortUp } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import '../../Assets/Css/Components/Partners.css'
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";
import allPartners from '../../partners'
import { FaExternalLinkAlt } from "react-icons/fa";

const Partners = ({api}) => {

    const [loading, setLoading] = useState(true)
    const [partners, setParners] = useState([])

    async function loadData() {
        let formattedPartners = allPartners.sort((a, b) => a.order - b.order)
        console.log(formattedPartners)
        setParners(formattedPartners)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])




    function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        let newServArr = []
        partners.forEach(prt => {
            let prtName = prt.name.replace(/[\s-]/g, '');
            let prtDesc = prt.description.replace(/[\s-]/g, '');
            if(!searchKey) {
                prt.hided = false;
            } else {
                if(prtName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) || prtDesc.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
                    prt.hided = false
            } else {
                prt.hided = true
            }
            }
            newServArr.push(prt)
            setParners(newServArr)
        })
      
    }

    return ( 
       <div className="bumpsApp">
        <div className="searchApp">
            <div className="searchBar">
                <input id="searchByName" spellCheck={false} type="text" onChange={(e) => { handleSearchBar(e.target)}} />
                <div className="srcBtn">
                <BiSearch/>
                </div>
            </div>
    
        </div>
        <div className="prtsList">

            {
                loading ?
                <div className="loadingApp bumps">
                    <BiLoaderAlt />
                </div>
                :
                partners.filter(prt => !prt.hided).map(prt => {
                    return (
                        <div className="prtMapped">
                            <div className="iconParent">
                                <div className="icon">

                                </div>
                            </div>
                            <div className="infs">
                                <h1>{prt.name} </h1>
                                <p>
                                    {prt.description}
                                </p>

                                <div className="btns">
                                    {
                                        prt.links.map(lk => {
                                            return (
                                                <a href={lk.url} className="joinBtn">
                                                {lk.name} <FaExternalLinkAlt /> 
                                                </a>
                                            )
                                        })
                                    }
                                
                                </div>
                            </div>

                        </div>
                    )
                })
            }

        </div>
       </div>
     );
}
 
export default  Partners;