import { BiLoaderAlt } from 'react-icons/bi';
function Loading() {
    return (
 <div className="loading">
        <div className="loader">
            <BiLoaderAlt className='loaderIcon' />
        </div>
        </div>
    )
}
export default Loading