import {  BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { MdEmojiEmotions } from "react-icons/md";
import { FaCrown, FaListUl, FaStarOfLife } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { BiSolidDashboard } from "react-icons/bi";
import '../../Assets/Css/Components/Commands.css'
import { useEffect, useState } from "react";
import axios from "axios"

const Commands = ({api}) => {

    const [sortOpen, setSortOpen] = useState(false)
    const [sort, setSort] = useState('all')
    const [loading, setLoading] = useState(true)
    const [commands, setCommands] = useState([])

    const commandsCategory = [
        {value: "all", label: <><FaListUl /> Tout</>},
        {value: "utilitary", label: <><BiSolidDashboard/> Utilitaires</>},
        {value: "moderation", label: <><FaCrown/> Modération</>},
        {value: "configuration", label: <><FaGear/> Configuration</>},
        {value: "fun", label: <><MdEmojiEmotions/> Divertissement</>},
    ]

    async function loadData() {
        let res = await axios(`${api}/commands`)

        if(!res.data) return;

        setCommands(res.data)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
        /*setInterval(() => {
            loadData()
        }, 5000)*/
    }, [])

    function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        let newCmdsArr = []
        commands.forEach(cmd => {
            let servName = cmd.name.replace(/[\s-]/g, '');
            let servDesc = cmd.description.replace(/[\s-]/g, '');
            if(!searchKey) {
                cmd.hided = false;
            } else {
                if(servName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) || servDesc.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
                    cmd.hided = false
            } else {
                cmd.hided = true
            }
            }
            newCmdsArr.push(cmd)
            setCommands(newCmdsArr)
        })
      
    }

    return ( 
       <div className="CommandsApp">
        <div className="searchApp">
            <div className="searchBar">
                <input id="searchByName" spellCheck={false} type="text" onChange={(e) => { handleSearchBar(e.target)}} />
                <div className="srcBtn">
                <BiSearch/>
                </div>
            </div>
            <div className={`sortMenu${sortOpen?" open":""}`}>
                <div className="label" onClick={()=>setSortOpen(!sortOpen)}>
                <div className="txt">
                {commandsCategory.find(o => o.value===sort).label}
                </div>
                <div className="btn">
                <BiChevronUp/>
                </div>
                </div>
                <div className="drop">
                    <ul> 
                        {commandsCategory.map(o => {
                            return (
                                <li key={o.value} className={o.value === sort ? "active":""} onClick={() => {
                                    setSort(o.value)
                                    setSortOpen(!sortOpen)
                                    }}>
                                {o.label}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>


        <div className="commandsList">
            {
                 loading ?
                 <div className="loadingApp commands">
                     <BiLoaderAlt />
                 </div>
                 :
                commands.map(cmd => {
                    if(cmd.hided) return;
                    return (
                        <div key={cmd.name} className="command">
                            <div className="nameContainer">
                            <h1 className="name">
                                /{cmd.name}
                            </h1>
                            <div className={`options`}>
                            {cmd.options?.map(op => {
                                return (
                                    <div key={op.name} className={`option${op.required ? " required" : ""}`}>
                                        <div className="nmContainer">
                                        <h1 className="nm">
                                            {op.name}
                                        </h1>
                                        <div className="required">
                                            {op.required ? <FaStarOfLife/> : null}
                                        </div>
                                        </div>

                                       
                                    </div>
                                )
                            })}
                           </div>
                            </div>
                           
                            <p className="description">
                                {cmd.description}
                            </p>
                        </div>
                    )
                })
            }
        </div>
       </div>
     );
}
 
export default Commands;