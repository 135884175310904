import { BiChevronDown, BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { BsSortDown, BsSortNumericDown, BsSortNumericUp, BsSortUp } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import '../../Assets/Css/Components/Codes.css'
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";
import allPartners from '../../partners'
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";

const Codes = ({api}) => {

    const [loading, setLoading] = useState(true)
    const [codes, setCodes] = useState([])

    async function loadData() {
        let res = await axios(`${api}/codes/list`)
        console.log(res)
        if(res.data.data) {
        let formattedCodes = res.data.data.sort((a, b) => b.date - a.date)
        setCodes(formattedCodes)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])




    function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        let newServArr = []
        codes.forEach(prt => {
            let prtName = prt.name.replace(/[\s-]/g, '');
            let prtDesc = prt.description.replace(/[\s-]/g, '');
            if(!searchKey) {
                prt.hided = false;
            } else {
                if(prtName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) || prtDesc.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
                    prt.hided = false
            } else {
                prt.hided = true
            }
            }
            newServArr.push(prt)
            setCodes(newServArr)
        })
      
    }

    return ( 
       <div className="bumpsApp">
        <div className="searchApp">
            <div className="searchBar">
                <input id="searchByName" spellCheck={false} type="text" onChange={(e) => { handleSearchBar(e.target)}} />
                <div className="srcBtn">
                <BiSearch/>
                </div>
            </div>
    
        </div>
        <div className="codesApp">
        <div className="codesList">

            {
                loading ?
                <div className="loadingApp bumps">
                    <BiLoaderAlt />
                </div>
                :
                codes.filter(cd => !cd.hided).map(cd => {
                    return (
                        <div className="cdMapped">
                            
                            <div className="infs">
                              
                                <div className="txts">
                                <div className="image">
                                    <div className="img" onClick={() => {
                                        window.open(cd.link, '_blank').focus();
                                    }}>
                                <img src={cd.image} alt="" />
                                    </div>
                            </div>
                                 <h1>{cd.name} </h1>
                                <p>
                                    {cd.description}
                                </p>    
                                </div>
                               

                                <div className="btns">
                                    
                                    <div className="views">
                                        {cd.views || 0}
                                        <FaEye /> 
                                    </div>
                                      <Link to={`/code/${cd.id}`} className="viewBtn">
                                      Voir <FaExternalLinkAlt /> 
                                      </Link>
                                
                                </div>
                            </div>

                        </div>
                    )
                })
            }

        </div>
        </div>
       </div>
     );
}
 
export default  Codes;