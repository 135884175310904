import { useEffect, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/Channels.css'
import axios from "axios"
import Loading from '../../../Components/Loading';
import { FaSave, FaTrash } from 'react-icons/fa';
import { BiChevronDown, BiLoaderAlt, BiX } from 'react-icons/bi';
import React, { memo } from 'react'; 

const DashChannelsView =  ({api, user, guild}) => {

    
  const [serverData, setServerData] = useState("loading")
  const [serverBaseData, setServerBaseData] = useState("loading")
  const [serverChannels, setServerChannels] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  const [bumpChannelOpen, setBumpChannelOpen] = useState(false)
  const [logChannelOpen, setLogChannelOpen] = useState(false)



    async function loadUserData() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server?id=${guild.id}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          if(res.data.data) {
            setServerData(res.data.data)
            setServerBaseData(res.data.data)
          } else {
            setServerData(null)
          }
        } catch(e) {
            return;
        }
      }
      async function loadChannelsGuild() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server/${guild.id}/channels`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          //console.log(res.data.data)
           setServerChannels(res.data.data.filter(r => r.name !== "@everyone"))
        } catch (error) {
          console.error('Error:', error);
        }
      }

      useEffect(() => {
        loadUserData()
        loadChannelsGuild()
      }, [])


      async function handleChannelsSave() {
        setSaveLoading(true)


        try {
            const config = {
              method: 'POST',
              url: `${api}/server/${guild.id}/channelsconfig`,
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
              },
              data: {
                config: serverData
              }
            };
            let res = await axios(config)
            setSaveLoading(false)
            if(res.data.data) {
              setServerData(res.data.data)
              setServerBaseData(res.data.data)
            }
          } catch(e) {
            return;
          }


      }


            return (

            serverData === "loading" ?
            <Loading/>
            :
            serverData ?
            <div className="guildSettingsMain">
              <div className="channelsConfig">

                <div className="setPart bumpChannel">
                <h1 className="tltSet">
                    Salons d'arrivées des bumps
                </h1>
                <div className="selectChannel">
                    <div className={`label${bumpChannelOpen ? " open": ""}`} onClick={() => {
                        setBumpChannelOpen(!bumpChannelOpen)
                        setLogChannelOpen(false)
                    }}>
                        {serverChannels.find(ch => ch.id === serverData.bumpchannel)?.name || 'Non défini'}

                        <div className="drpBtn">
                            <BiChevronDown/>
                        </div>
                    </div>
                    <div className={`drp${bumpChannelOpen ? " open": ""}`}>
                        <div className="drpElList">
                            {
                               serverChannels.map(ch => {
                                    return (
                                    <div className="drpop"
                                    onClick={() => {
                                        setBumpChannelOpen(false)
                                        setServerData({...serverData, bumpchannel: ch.id})
                                    }}
                                    >
                                        {ch.name}
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                </div>

                <div className="setPart logChannel">
                <h1 className="tltSet">
                    Salons des logs
                </h1>
                <div className="selectChannel">
                    <div className={`label${logChannelOpen ? " open": ""}`} onClick={() => {
                        setLogChannelOpen(!logChannelOpen)
                        setBumpChannelOpen(false)
                    }}>
                        {serverChannels.find(ch => ch.id === serverData.logchannelid)?.name || 'Non défini'}

                        <div className="drpBtn">
                            <BiChevronDown/>
                        </div>
                    </div>
                    <div className={`drp${logChannelOpen ? " open": ""}`}>
                        <div className="drpElList">
                            {
                                 [{id: "", name: "Non défini"}, ...serverChannels].map(ch => {
                                    return (
                                    <div className="drpop"
                                    onClick={() => {
                                        setLogChannelOpen(false)
                                        setServerData({...serverData, logchannelid: ch.id})
                                    }}
                                    >
                                        {ch.name}
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                </div>
                <div className={`saveBtnSet${(JSON.stringify(serverBaseData) === JSON.stringify(serverData)) ? " disabled" : ""}`} onClick={() => {
                    if((JSON.stringify(serverBaseData) === JSON.stringify(serverData))) return;
                            handleChannelsSave()
                  }}>
                         {saveLoading ? <BiLoaderAlt className='ldgSet'/> :<FaSave/>}
              </div>
            </div>
            </div>
            :
            <div className="guildSettingsMain">
            Erreur
            </div>
            )
        
}

export default DashChannelsView