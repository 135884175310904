import { useEffect, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/Bumps.css'
import axios from "axios"
import Loading from '../../../Components/Loading';
import { FaSave, FaTrash } from 'react-icons/fa';
import { BiLoaderAlt, BiX } from 'react-icons/bi';
import React, { memo } from 'react'; 

const DashBumpRoleView =  ({api, user, guild}) => {

    
  const [serverData, setServerData] = useState("loading")
  const [roleList, setRoleList] = useState([{roleId: null, bumps: null, edited: Date.now()}])
  const [baseRoleList, setBaseRoleList] = useState([{roleId: null, bumps: null, edited: Date.now()}])
  const [serverRoles, setServerRoles] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)



    async function loadUserData() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server?id=${guild.id}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          if(res.data.data) {
            setServerData(res.data.data)
            if(res.data.data.rolesBump) {
                setBaseRoleList(res.data.data.rolesBump)
                setRoleList(res.data.data.rolesBump)
            }
          } else {
            setServerData(null)
          }
        } catch(e) {
            return;
        }
      }
      async function loadRolesGuild() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server/${guild.id}/roles`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          //console.log(res.data.data)
           setServerRoles(res.data.data.filter(r => r.name !== "@everyone"))
        } catch (error) {
          console.error('Error:', error);
        }
      }

      useEffect(() => {
        loadUserData()
        loadRolesGuild()
      }, [])


      async function handleRolesSave() {
        setSaveLoading(true)


        try {
            const config = {
              method: 'POST',
              url: `${api}/server/${guild.id}/rolesbump`,
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
              },
              data: {
                roles: roleList
              }
            };
            let res = await axios(config)
            setSaveLoading(false)
            if(res.data.data) {
              setBaseRoleList(res.data.data.rolesBump || [{roleId: null, bumps: null, edited: Date.now()}])
              setRoleList(res.data.data.rolesBump || [{roleId: null, bumps: null, edited: Date.now()}])
            }
          } catch(e) {
            return;
          }


      }


            return (

            serverData === "loading" ?
            <Loading/>
            :
            serverData ?
            <div className="guildSettingsMain">
                <div className="rolesSet">
                    <h1 className="tltSet">
                        Rôle par nombre de bumps
                    </h1>
                    <div className="rlList">
                        <div className="rlListFlex">
                        {
                            roleList.map((rl, i) => {
                                 return (
                    <div className="rlSetView">
                                <div className="rlset" id={"rlsId_"+i}>
                                    <input
                                    autoComplete={"off"}
                                    onChange={(e) => {
                                       // if(!Number.isInteger(Number(e.target.value))) return;
                                       if (e.target.value && !/^\d+$/.test(e.target.value)) return;
                                        let newArr = roleList.map((r, rlI) => {
                                            if (rlI === i) {
                                              return { ...r, bumps: e.target.value, edited: Date.now() };
                                            }
                                            return r;
                                          });
                                        setRoleList(newArr)
                                        console.log(newArr)
                                    }}
                                    type="text" className='bpc' name="bumpscount" placeholder='1...' value={rl.bumps?.trim()||""}/>
                                        {
                                            serverRoles.find(r => r.id === rl.roleId) ?
                                            (<div className="rlView">
                                            <div className="labelRlVw">
                                            {serverRoles.find(r => r.id === rl.roleId).name || 'Unknown'}
                                            <div className="dlBtn"
                                            onClick={() => {
                                                let newArr = roleList.map((r, rlI) => {
                                                    if (rlI === i) {
                                                      return { ...r, roleId: null, edited: Date.now()};
                                                    }
                                                    return r;
                                                  });
                                                setRoleList(newArr)
                                            }}>
                                            <BiX/>
                                            </div>
                                                </div>
                                               
                                            </div>)
                                            :
                                            (<div className="rlView search">
                                                <div className="label">
                                                    <input
                                                    onClick={(e) => {
                                                        e.target.closest('.rlView').querySelector('.drp').classList.toggle('open')
                                                    }}
                                                    onChange={(e) => {
                                                        let newArr = roleList.map((r, rlI) => {
                                                            if (rlI === i) {
                                                              return { ...r, roleId: e.target.value, edited: Date.now() };
                                                            }
                                                            return r;
                                                          });
                                                        setRoleList(newArr)
                                                    }}
                                                    className='rlSrc' placeholder='@everyone...' type="text"/>
                                                 </div>
                                                 <div className="drp">
                                                    <div className='drpElList'>
                                                     {serverRoles.filter(src => {
                                                        if(roleList[i].roleId?.length > 0) {
                                                             return src.name.toLowerCase().replace(/[\s-]/g, '').includes(roleList[i].roleId.toLowerCase().replace(/[\s-]/g, ''))
                                                        } else {
                                                            return true;
                                                        }
                                                     }).map(e => {
                                                       return (
                                                       <div className="drpop"
                                                       onClick={() => {
                                                        let newArr = roleList.map((r, rlI) => {
                                                            if (rlI === i) {
                                                              return { ...r, roleId: e.id, edited: Date.now() };
                                                            }
                                                            return r;
                                                          });
                                                        setRoleList(newArr)
                                                       }}
                                                       >{e.name}</div>
                                                       )
                                                    })}     
                                                    </div>
                                                  
                                                 </div>
                                            </div>)
                                        }
                                    </div>
                                    <div className="dltRlset" onClick={(() => {
                                     let newArr = roleList.filter((r, rlI) => rlI !== i)
                                       // console.log(newArr)
                                       setRoleList(newArr)
                                    })}>
                                    <FaTrash/>
                                    </div>
                                    </div>
                                )
                            })
                        }
                        </div>

                        <div className="addAnt" onClick={() => {
                                 let missing = false;
                                 roleList.sort((a, b) => a.edited - b.edited).forEach((rls, i) => {
                                    console.log(roleList.filter(r => r.bumps === rls.bumps))
                                    if(missing) return;
                                    //console.log(rls)
                                    let el = document.querySelector(`#rlsId_${i}`);
                                    if(!rls.bumps || rls.bumps === 0) {
                                        missing = true;
                                        el.querySelector('.bpc').click()
                                        return el.querySelector('.bpc').focus();
                                    }

                                    if(!rls.roleId || !serverRoles.find(r => r.id === rls.roleId)) {
                                        missing = true;
                                        el.querySelector('.rlSrc').click()
                                        return el.querySelector('.rlSrc').focus();
                                    }
                                 })
                                 if(missing) return;
                                 let newArr = [...roleList, { roleId: null, bumps: null }];
                                 setRoleList(newArr)
                        }}>
                            Ajouter
                        </div>
                    </div>
                    
                    <div className={`saveBtnSet${(
                        JSON.stringify(baseRoleList.map(rl => { return {bumps: rl.bumps, roleId: rl.roleId}})) === JSON.stringify(roleList.map(rl => { return {bumps: rl.bumps, roleId: rl.roleId}}))
                    ) ? " disabled" : ""}`} onClick={() => {
                        let baseValue = JSON.stringify(baseRoleList.map(rl => { return {bumps: rl.bumps, roleId: rl.roleId}}))
                        let value = JSON.stringify(roleList.map(rl => {return {bumps: rl.bumps, roleId: rl.roleId}}))
                        console.log(baseValue, value)
                            if(baseValue === value) return;

                            let missing = false;
                            roleList.sort((a, b) => a.edited - b.edited).forEach((rls, i) => {
                               if(missing) return;
                               let el = document.querySelector(`#rlsId_${i}`);
                               if(!rls.bumps || rls.bumps === 0) {
                                   missing = true;
                                   el.querySelector('.bpc').click()
                                   return el.querySelector('.bpc').focus();
                               }

                               if(!rls.roleId || !serverRoles.find(r => r.id === rls.roleId)) {
                                   missing = true;
                                   el.querySelector('.rlSrc').click()
                                   return el.querySelector('.rlSrc').focus();
                               }
                            })
                            if(missing) return;


                            handleRolesSave()
                        }}>
                            {saveLoading ? <BiLoaderAlt className='ldgSet'/> :<FaSave/>}
                        </div>
                </div>
            </div>
            :
            <div className="guildSettingsMain">
            Erreur
            </div>
            )
        
}

export default DashBumpRoleView