import { useEffect } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/Server.css'
import axios from "axios"

const DashUserView =  ({api, user, setUser}) => {

    async function loadUserData() {
        try {
          const config = {
            method: 'POST',
            url: `${api}/user`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          console.log(res.data)
          if(res.data.user) {
            setUser(res.data.user)
          }
        } catch(e) {
            return;
        }
      }


      useEffect(() =>{
        loadUserData()
      }, [])


    return (
      <div className="userSettings">
        <div className="bumpsCount counter">
            <h3>{user.extra.bumpsCount}</h3>
            <h1>Bumps</h1>
        </div>
        <div className="votesCount counter">
            <h3>{user.extra.votesCount}</h3>
            <h1>Votes</h1>
        </div>
      </div>
    )
}

export default DashUserView