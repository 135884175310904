import { useEffect, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/General.css'
import axios from "axios"
import Loading from '../../../Components/Loading';

const DashGeneralView =  ({api, user, guild}) => {

    
  const [serverData, setServerData] = useState("loading")
  const [allServers, setAllServers] = useState([])

    async function loadUserData() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server?id=${guild.id}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          //console.log(res.data)
          if(res.data.data) {
            setServerData(res.data.data)
          } else {
            setServerData(null)
          }
        } catch(e) {
            return;
        }
      }

      async function loadAllServers() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/servers`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          console.log(res.data)
          if(res.data.data) {
            setAllServers(res.data.data)
          } else {
            setAllServers([])
          }
        } catch(e) {
            return;
        }
      }


      useEffect(() =>{
        loadUserData()
        loadAllServers()
      }, [])

      const AverageRating = () => {
        let votes = serverData.votes
        if(votes) {
        const totalRatings = serverData.votes.reduce((sum, vote) => sum + vote.rating, 0);
        const averageRating = totalRatings / votes.length;
        const roundedRating = Math.round(averageRating * 2) / 2;

          return (roundedRating);
        } else {
            return ("~")
        }
    }

            return (

            serverData === "loading" ?
            <Loading/>
            :
            serverData ?
            <div className="guildSettingsMain">
                {//JSON.stringify(serverData, null, 2)
                }
                <div className="statsCounter">
                <div className="bumpsCount counter">
            <h3>{serverData.bumpcount}</h3>
            <h1>Bumps</h1>
            </div>
            <div className="votesCount counter">
            <h3>{serverData.votes?.length || 0}</h3>
            <h1>Votes</h1>
            </div>  
            <div className="avarageRating counter">
            <h3><AverageRating/></h3>
            <h1>Moyenne avis</h1>
            </div>    
            <div className="ranking counter">
            <h3>{allServers?.sort((a, b) => b.bumpcount - a.bumpcount).findIndex(s => s.guildID===guild.id)+1 || "~"}</h3>
            <h1>Classement</h1>
            </div>     
            </div>

            {serverData.bumpsUser?.length > 0 ?
            <div className="topBumpers">
            <h1>Meilleurs bumpers :</h1>
            <div className="bpmrList">
                {serverData.bumpsUser.sort((a, b) => b.bumpsCount - a.bumpsCount).slice(0, 5).map((u, i) => {
                    console.log(u)
                    let user = serverData.members.find(us => us.id === u.userId)
                    if(!user) return (
                        null
                    )
                    return (
                        <div className="bpmr" key={i}>
                           
                            <div className="avatar">
                                {
                                    user.iconURL ?
                                    <img src={user.iconURL} alt="" />
                                    :
                                    null
                                }
                            </div>
                            <div className="tp">
                                {i+1} 
                            </div>
                            <div className="name">
                                {user.username.slice(0, 20)}
                            </div>

                            <div className="bmpsccc">
                                <span>{u.bumpsCount}</span> <b>bumps</b>
                            </div>
                        </div>
                    )
                })    }
            </div>   
            </div>
           
            :
            null
            }
            </div>
            :
            <div className="guildSettingsMain">
            Erreur
            </div>
            )
        
}

export default DashGeneralView