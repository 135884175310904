import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import ServersSelect from './ServersSelect';
import { BiSolidDashboard, BiSolidMessageSquareDetail, BiSolidRocket } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa6';
import { FaBookmark, FaCode, FaCrown, FaDev, FaJava, FaJs } from 'react-icons/fa';

const MemoizedServersSelect = memo(ServersSelect);

const SideMenu = ({ guilds, api, user, id, open, setOpen, guild, category }) => {
  return (
    <div className={`sideMenu${open ? ' open' : ''}`}>
      <MemoizedServersSelect guilds={guilds} api={api} user={user} id={id} />
      {guild ? (
        guild === 'notfound' ? (
          <div className="serverMenu skeleton">
            <div className="top">
              <div className="icon"></div>
              <div className="name">‎</div>
            </div>
            <div className="srvMnOp">
              <ul>
                <Link>‎</Link>
                <Link>‎</Link>
                <Link>‎</Link>
              </ul>
            </div>
          </div>
        ) : (
          guild.bot ? (
            <div className="serverMenu">
              <div className="top">
                <div className="icon">
                  {guild.icon ? (
                    <img src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`} alt="" />
                  ) : (
                    <span>{guild.name.slice(0, 1)}</span>
                  )}
                </div>
                <div className="name">{guild.name.slice(0, 30)}</div>
              </div>
              <div className="srvMnOp">
                <ul>
                  <Link to={`/dashboard/${guild.id}`} className={`${!category ? 'active' : ''}`}>
                    <BiSolidDashboard /> Général
                  </Link>
                  <Link to={`/dashboard/${guild.id}/bump`} className={`${category === 'bump' ? 'active' : ''}`}>
                    <BiSolidRocket /> Bump
                  </Link>
                  <Link to={`/dashboard/${guild.id}/rolebump`} className={`${category === 'rolebump' ? 'active' : ''}`}>
                  <FaBookmark /> Rôle Bumps
                  </Link>
                  <Link to={`/dashboard/${guild.id}/channels`} className={`${category === 'channels' ? 'active' : ''}`}>
                    <BiSolidMessageSquareDetail /> Salons
                  </Link>
                </ul>
              </div>
            </div>
          ) : (
            <div className="serverMenu skeleton">
              <div className="top">
                <div className="icon"></div>
                <div className="name">‎</div>
              </div>
              <div className="srvMnOp">
                <ul>
                  <Link>‎</Link>
                  <Link>‎</Link>
                  <Link>‎</Link>
                </ul>
              </div>
            </div>
          )
        )
      ) : (
        <div className="serverMenu">
          <div className="top">
            <div className="icon">
              <img
                className="avatar"
                src={user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : `https://cdn.discordapp.com/embed/avatars/0.png`}
                alt={user.username}
              />
            </div>
            <div className="name">Votre profil</div>
          </div>
          <div className="srvMnOp">
            <ul>
              <Link to="/dashboard" className={!id ? "active": ""}>
                <FaUser /> Général
              </Link>
              {
                user.isAdmin ?
                <Link to="/dashboard/admin/dash" className={category === "dash" ? "active" : ""}>
                <FaCrown /> Admin
              </Link>
              :
              null
              }
               {
                user.isCodeMaker || user.isAdmin ?
                <Link to="/dashboard/admin/code" className={category === "code" ? "active" : ""}>
                <FaCode /> Codes
              </Link>
              :
              null
              }
        
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(SideMenu);