import { useEffect, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/Bumps.css'
import axios from "axios"
import Loading from '../../../Components/Loading';
import { FaSave } from 'react-icons/fa';
import { BiLoaderAlt, BiX } from 'react-icons/bi';
import React, { memo } from 'react'; 

const catList = [
    { label: "Gaming", value: "gaming" },
    { label: "Publicitaire", value: "publicitaire" },
    { label: "Roleplay", value: "roleplay" },
    { label: "Communautaire", value: "communautaire" },
    { label: "Développement", value: "developpement" },
    { label: "Informatique", value: "informatique" },
    { label: "Graphisme", value: "graphisme" },
    { label: "Art", value: "art" },
    { label: "Musique", value: "musique" },
    { label: "Support", value: "support" },
    { label: "Réseaux", value: "reseaux" },
    { label: "Giveaway", value: "giveaway" },
    { label: "Animé", value: "anime" },
    { label: "Fun", value: "fun" },
    { label: "Chill", value: "chill" },
    { label: "Emoji", value: "emoji" },
    { label: "Nitro", value: "nitro" },
    { label: "Film", value: "film" },
    { label: "NSFW", value: "nsfw" },
    { label: "Sport", value: "sport" },
    { label: "Etudes", value: "etudes" }
  ];
const DashBumpView =  ({api, user, guild}) => {

    
  const [serverData, setServerData] = useState("loading")
  const [descriptionLoading, setDescriptionLoading] = useState(false)
  const [catsLoading, setCatsLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [selectedCat, setSelectedCat] = useState([])


  
  

    async function loadUserData() {
        try {
          const config = {
            method: 'GET',
            url: `${api}/server?id=${guild.id}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          console.log(res.data)
          if(res.data.data) {
            setServerData(res.data.data)
            setSelectedCat(res.data.data.categorys || [])
            setDescription(res.data.data.description || "")
          } else {
            setServerData(null)
          }
        } catch(e) {
            return;
        }
      }

      async function handleAskVerif() {
        try {
            const config = {
              method: 'POST',
              url: `${api}/server/${guild.id}/verif`,
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
              },
              data: {
                serverName: guild.name
              }
            };
            let res = await axios(config)
            if(res.data.data) {
              setServerData(res.data.data)
            }
          } catch(e) {
            return;
          }
      }


      function changeCats(value) {

      

        //console.log(JSON.stringify(selectedCat), JSON.stringify(serverData.categorys || []))
        let newArr = []
        if(selectedCat.includes(value)) newArr = selectedCat.filter(b => b !== value)
        else {
            if(selectedCat.length === 5) return;
          newArr = [...selectedCat, value]
        }
       setSelectedCat(newArr)
    }
      useEffect(() =>{
        loadUserData()
      }, [])

      async function handleDescSave() {

        if(!serverData.verified) return;

        setDescriptionLoading(true)

        try {
            const config = {
              method: 'POST',
              url: `${api}/server/${guild.id}/description`,
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
              },
              data: {
                description: description
              }
            };
            let res = await axios(config)
            setDescriptionLoading(false)
            if(res.data.data) {
              setServerData(res.data.data)
            }
          } catch(e) {
            return;
          }
      }

      async function handleCatsSave() {
        if(!serverData.verified) return;
        setCatsLoading(true)
        try {
            const config = {
              method: 'POST',
              url: `${api}/server/${guild.id}/categorys`,
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
              },
              data: {
                categorys: selectedCat
              }
            };
            let res = await axios(config)
            setCatsLoading(false)
            if(res.data.data) {
              setServerData(res.data.data)
              setSelectedCat(res.data.data.categorys || [])
            }
          } catch(e) {
            return;
          }
      }


            return (

            serverData === "loading" ?
            <Loading/>
            :
            serverData ?
            <div className="guildSettingsMain">
                <div className="status">
                    <h1>Status :</h1>
                    <h3>{serverData.verified ? 'Vérifié' : serverData.isInVerif ? 'En attente' : 'Non vérifié'}</h3>

                    <div onClick={(() => {
                        if(!serverData.isInVerif && !serverData.verified) {
                            handleAskVerif()
                        }
                    })} className={`ask${serverData.isInVerif ? " disabled": serverData.verified ? " disabled": ""}`}>
                        Demander
                    </div>
                </div>

                <div className={`settingsList${serverData.verified ? "": " disabled"}`}>
                    <div className="desc">
                        <h1 className="tltSet">
                            Description
                        </h1>
                        <textarea spellCheck="false" name="desc" placeholder='...' className='txtSet' id="desc" onChange={(e) => {
                            if(!serverData.verified) return;
                            setDescription(e.target.value)
                        }} value={description}></textarea>
                        <div className={`saveBtnSet${(description.length == 0 || description === serverData.description) ? " disabled" : ""}`} onClick={() => {
                            handleDescSave()
                        }}>
                            {descriptionLoading ? <BiLoaderAlt className='ldgSet'/> :<FaSave/>}
                        </div>
                    </div>

                    <div className="categorysSet">
                    <h1 className="tltSet">
                            Catégories
                    </h1>
                    <div className="catList">
                        {catList.map((e, i) => {
                            return (
                                  <div key={i} className={`ctSet ${selectedCat.includes(e.value)?' selected': ''}`} onClick={() => changeCats(e.value)}>
                                  <div className="lbl">
                                      {e.label}
                                  </div>
                                  <div className="hided">{e.label}</div>
                              <div className="icon"><BiX/></div>
                              </div> 
                            )
                        })}
                    </div>
                    <div className={`saveBtnSet cts${(JSON.stringify(serverData.categorys || []) === JSON.stringify(selectedCat)) ? " disabled" : ""}`} onClick={() => {
                            handleCatsSave()
                        }}>
                            {catsLoading ? <BiLoaderAlt className='ldgSet'/> :<FaSave/>}
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="guildSettingsMain">
            Erreur
            </div>
            )
        
}

export default memo(DashBumpView)