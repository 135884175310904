import '../../Assets/Css/Components/Status.css'
const Status =  ({}) => {
    return (
      <div className="statusPage">
       
       
        <div style={{height: "500px"}}></div>
      </div>
    )
}

export default Status