import { useEffect, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/UserAdmin.css'
import axios from "axios"
import { FaDotCircle, FaSearch } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';

const DashUserView =  ({api, user, setUser}) => {


  
  const [servers, setServers] = useState([])
  const [blServers, setBlServers] = useState([])

    async function loadUserData() {
        try {
          const config = {
            method: 'POST',
            url: `${api}/user`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
       //   console.log(res.data)
          if(res.data.user) {
            if(!res.data.user.isAdmin) return window.location.href = "/dashboard"
            setUser(res.data.user)
          }
        } catch(e) {
            return;
        }
      }


      async function loadData() {
          let res = await axios(`${api}/servers`)
  
      //    console.log(res)
          if(!res.data.data) return;
  
          setServers(res.data.data)
      }

      async function loadBlData() {
        const config = {
          method: 'GET',
          url: `${api}/blacklisted/servers`,
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
          }
        };
        let res = await axios(config)
        if(!res.data.data) return;

        console.log(res)

        setBlServers(res.data.data)
    }
  
      useEffect(() =>{
        loadData()
        loadBlData()
        loadUserData()
      }, [])


      function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        const newSrvs = servers.map(srv => {
          let servName = srv.guildName.replace(/[\s-]/g, '');
          if(!searchKey) srv.hided = false;
          else {
          if(servName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
            srv.hided = false
          } else {
            srv.hided = true;
          }
          }
          return srv
        })
        setServers([...newSrvs])
    }

    
    function handleBlackListSearchBar(e) {
      let searchKey = e.value.replace(/[\s-]/g, '');
      const newSrvs = blServers.map(srv => {
        let servName = srv.guildName.replace(/[\s-]/g, '');
        if(!searchKey) srv.hided = false;
        else {
        if(servName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
          srv.hided = false
        } else {
          srv.hided = true;
        }
        }
        return srv
      })
      setBlServers([...newSrvs])
  }


  async function handleBlacklistAdd(guildId) {


    try {
      const config = {
        method: 'POST',
        url: `${api}/blacklisted/${guildId}/add`,
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
        }
      };
      let res = await axios(config)
      if(res.data.status === 404) return;
      if(res.data.data) {
        setBlServers(res.data.data)
        loadData()
      }
    } catch(e) {
      return;
    }

  }

  async function handleBlacklistRemove(guildId) {
    try {
      const config = {
        method: 'POST',
        url: `${api}/blacklisted/${guildId}/remove`,
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
        }
      };
      let res = await axios(config)
      if(res.data.status === 404) return;
      if(res.data.data) {
        setBlServers(res.data.data)
        loadData()
      }
    } catch(e) {
      return;
    }
  }


  async function handleLeaveServer(guildId) {


    try {
      const config = {
        method: 'POST',
        url: `${api}/server/${guildId}/leave`,
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
        }
      };
      let res = await axios(config)
      if(res.data.status === 404) return;
      if(res.data.data) {
        loadData()
      }
    } catch(e) {
      return;
    }

  }



    const ServerComp = ({srv, bl}) => {
      const [optOpen, setOptOpen] = useState(false)

      if(bl) {
        srv.guildID = srv.id
        srv.guildName = srv.name
      }

      return (
        <div className="srvMapped" key={srv.guildID}>

        <div className="infs">
        <div className="icon">
                        {srv.server?.iconURL?
                        <img src={srv.server?.iconURL} alt='' />
                        :
                        null}
        </div>
        <h1 className="name" onClick={() => {
          return window.location.href = `/server/${srv.guildID}`
        }}>
        {srv.guildName}
        </h1>
        </div>


<div className="lftBtn">
        <div className={`optsBtnSrv${optOpen ? " open": ""}`}>
          <div className="obtnSrv" onClick={() => {
            setOptOpen(!optOpen)
          }}>
            
          <HiDotsVertical/>
          </div>
          <div className="obtnDrop" onClick={() => {
            setOptOpen(false)
          }}>
            {
              bl ?
              <ul className="optsDrp">
              <li onClick={() => {
                handleBlacklistRemove(srv.guildID)
              }}>
                Retirer 
              </li>
            </ul>
              :
              <ul className="optsDrp">
              <li onClick={() => {
                handleLeaveServer(srv.guildID)
              }}>
                Quitter
              </li>
              <li onClick={() => {
                handleBlacklistAdd(srv.guildID)
              }}>
                Blacklisté
              </li>
            </ul>
            }
           
          </div>
        </div>
      
  
</div>
      </div>
      )
    }


    return (
        
        <div className="serversAdminSets">

          <h1 className='bgTilt'>
            Liste des serveurs
          </h1>

          <div className="srcBr">
            <input type="text"  id="srcBar" spellCheck={false} onChange={(e) => { handleSearchBar(e.target)}} />
            <div className="srcBnt">
            <FaSearch/>
            </div>
          </div>



          <div className="srvLsts">
            {
              servers.filter(srv => srv.server && !srv.hided).map(srv => {
                return(
                  <ServerComp key={srv.guildID} bl={false} srv={srv}/>
                )
              })
            }
          </div>



          <h1 className='bgTilt scd'>
            Blacklist des serveurs
          </h1>

          <div className="srcBr">
            <input type="text" id="blSrcBar"  spellCheck={false} onChange={(e) => { handleBlackListSearchBar(e.target)}} />
            <div className="srcBnt">
            <FaSearch/>
            </div>
          </div>



          <div className="srvLsts">
            {
              blServers.filter(srv => !srv.hided).map(srv => {
                return(
                  <ServerComp key={srv.guildID} bl={true} srv={srv}/>
                )
              })
            }
          </div>


      </div>
    )
}

export default DashUserView