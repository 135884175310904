import { BiChevronDown, BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { BsSortDown, BsSortNumericDown, BsSortNumericUp, BsSortUp } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import '../../Assets/Css/Components/CodeInfos.css'
import { useEffect, useState } from "react";
import axios from "axios"
import { Link, useParams } from "react-router-dom";
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";
import allPartners from '../../partners'
import { FaArrowRight, FaExternalLinkAlt, FaChevronRight } from "react-icons/fa";
import { FaArrowRightArrowLeft, FaEye } from "react-icons/fa6";

const baseCode = `$nomention
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]`

const CodeInfos = ({api}) => {

    const [loading, setLoading] = useState(true)
    const [codes, setCodes] = useState([])
    const [code, setCode] = useState({name: null, unlocked: false})
    const [codeContent, setCodeContent] = useState(
`$nomention
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]
$function[;]`)

    const {id} = useParams()

    async function loadData() {
        let res = await axios(`${api}/codes/list`)
        console.log(res)
        if(res.data.data) {
        let formattedCodes = res.data.data.sort((a, b) => b.date - a.date)
        setCodes(formattedCodes)
        setCode({...formattedCodes.find(cd => cd.id === id), unlocked: true})
        editorWrap()
        }
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])



    async function editorWrap() {
        let editor = document.querySelector('.codeEditor')
        let texts = editor.querySelectorAll('.lineContent')
        texts.forEach(ln => {
            let text = ln.innerText;
    
        
        console.log(text)
        text = text.replace(/\$nomention/g, '<span class="cd-nomention">\$nomention</span>');
        // Remplace les fonctions par des span avec la classe appropriée
        //text = text.replace(/\$(\w+)/g, '<span class="cd-function">$$$1</span>');
        text = text.replace(/\$(\w+)/g, function(match, p1) {
            if (match !== '$nomention') {
                return '<span class="cd-function">\$' + p1 + '</span>';
            }
            return match;
        });

        // Remplace les crochets par des span avec la classe appropriée
        text = text.replace(/\[/g, '<span class="cd-bracket">[</span>');
        text = text.replace(/\]/g, '<span class="cd-bracket">]</span>');
    
        // Gère les points-virgules entre crochets
        text = text.replace(/\[([^]*?)\]/g, function(match, p1) {
            return '[<span class="cd-semicolon">' + p1.replace(/;/g, '<span class="cd-semicolon">;</span>') + '</span>]';
        });
    
        text = text.replace(/\[([^]*?)\]/g, function(match, p1) {
            return '<span class="cd-semicolon">[</span><span class="cd-text">' + p1.replace(/;/g, '<span class="cd-semicolon">;</span>') + '</span><span class="cd-semicolon">]</span>';
        });
        // Le reste du texte en vert
        text = '<span class="cd-text">' + text + '</span>';
    
        ln.innerHTML = text;

       })
    
    }


    return ( 
        <div className="codeApp">
            {
                loading ?
                ""
                :
                code ?
                <div className="codeAppMain">

                    <div className="topInfs">
                        <div className="lft">
                            <div className="nameAndViews">
                                <div className="a">
                                  <h1>{code.name}</h1>
                            <h3>{code.views || 0} <FaEye/></h3>  
                                </div>
                                <div className="b">
                                   <a href={code.link} className="btnShare">Voir <FaExternalLinkAlt/></a>
                                </div>
                            
                            </div>
                            <p>{code.description.slice(0, 512)}</p>
                        </div>
                        <div className="rgt">
                            <div className="image">
                                <img src={code.image} alt="" />
                            </div>
                        </div>
                    </div>



                    <div className="codeWrp">
                     {
                        !code.unlocked ?
                         
                    <div className="passwordUnlock">
                                  <input spellCheck={false} autoComplete="false" type="text" placeholder="Entrer le mot de passe"/>
                                  <div className="verifyPsw">
                                    <FaChevronRight/>
                                  </div>
                               </div>
                               :
                               ""
                               }
                        {
                            !code.unlocked ?
                            <div className="codeEditor locked">
                               {
                                codeContent.split('\n').map((e, i) => {
         
                                    return (
                                        <div className="line">
                                            <div className="lineNumber">{i+1}</div>
                                            <div className="lineContent">{e}</div>
                                        </div>
                                    )
                
                                   })
                               }
                            </div>
                            :
                            <div className="codeEditor">
                            {
                            codeContent.split('\n').map((e, i) => {
         
                             return (
                                 <div className="line">
                                     <div className="lineNumber">{i+1}</div>
                                     <div className="lineContent">{e}</div>
                                 </div>
                             )
         
                            })
                            }
                            </div>
                        }

                    </div>

                </div>
                :
                <div className="codeAppMain notfound">
                    Non trouvé
                </div>
            }
        </div>
     );
}
 
export default  CodeInfos;