import { Link, useParams } from 'react-router-dom'
import '../../Assets/Css/Components/Server.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from '../Components/Loading'
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";
import { FaPen } from "react-icons/fa";
import { RiShareFill } from "react-icons/ri";
import { BiCheck, BiLoaderAlt } from 'react-icons/bi'
import { MdDelete } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";

const catsOptions = [
  { label: "Gaming", value: "gaming" },
  { label: "Publicitaire", value: "publicitaire" },
  { label: "Roleplay", value: "roleplay" },
  { label: "Communautaire", value: "communautaire" },
  { label: "Développement", value: "developpement" },
  { label: "Informatique", value: "informatique" },
  { label: "Graphisme", value: "graphisme" },
  { label: "Art", value: "art" },
  { label: "Musique", value: "musique" },
  { label: "Support", value: "support" },
  { label: "Réseaux", value: "reseaux" },
  { label: "Giveaway", value: "giveaway" },
  { label: "Animé", value: "anime" },
  { label: "Fun", value: "fun" },
  { label: "Chill", value: "chill" },
  { label: "Emoji", value: "emoji" },
  { label: "Nitro", value: "nitro" },
  { label: "Film", value: "film" },
  { label: "NSFW", value: "nsfw" },
  { label: "Sport", value: "sport" },
  { label: "Etudes", value: "etudes" }
];


const Server =  ({api, user}) => {


    const [server, setServer] = useState(null)
    const [loading, setLoading] = useState(true)
    const [shareOpen, setShareOpen] = useState(false)
    const [reportPanelOpen, setReportPanel] = useState([])
    const [reportSend, setReportSend] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    const [sendLoading, setSendLoading] = useState(false)
    const [reportServer, setReportServer] = useState(false)
    const [reportMsg, setReportMsg] = useState("")

    const [stars, setStars] = useState(0)
    const [fdb, setFdb] = useState("")
    const {id} = useParams()

    const [datePosts, setDatePosts] = useState([]);
    
    const addServerIdToLocalStorage = (serverId) => {
        const serverIds = JSON.parse(localStorage.getItem('serverIds')) || [];
        if (!serverIds.find(e => e.id === server.extra.id)) {
          serverIds.push({ id: serverId, timestamp: Date.now() });
          localStorage.setItem('serverIds', JSON.stringify(serverIds));
        }
      };
   const cleanLocalStorage = () => {
        const serverIds = JSON.parse(localStorage.getItem('serverIds')) || [];
        const now = Date.now();
        const oneDay = 24 * 60 * 60 * 1000; // 1 jour en millisecondes
        const filteredServerIds = serverIds.filter(({ timestamp }) => now - timestamp < oneDay);
  
        localStorage.setItem('serverIds', JSON.stringify(filteredServerIds));
      };

    useEffect(() => {
      cleanLocalStorage();
    }, []);

    

    async function loadData() {
        let res = await axios(`${api}/server?id=${id}`)

        if(!res.data.data) return;
        if(res.data.status === 303) setServer(null)
        else {
            setServer(res.data.data)
            //console.log(res.data.data)
            let userFdb = res.data.data.votes?.find(v => v.userId === user.id);
            if(userFdb) {
                setFdb(userFdb.content)
                setStars(userFdb.rating)
            }
        }
        setLoading(false)

        //console.log(server)
    }

    useEffect(() => {
         loadData().then(() => {
           const queryParameters = new URLSearchParams(window.location.search)
        const scrollTo = queryParameters.get("scrollTo") || ''
        const element = document.getElementById('vote');
        console.log(scrollTo,element)
       if (element && scrollTo === "vote") {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
       }
         })
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Met à jour la date pour chaque élément dans server.votes
            setDatePosts(prevDates => (
                server?.votes.map(fdb => formatDate(fdb.date))
            ));
        }, 1000);
    
        // Nettoie l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [server?.votes]);
    
    useEffect(() => {
    if(JSON.parse(localStorage.getItem('serverIds')).find(s => s.id === server?.extra.id)) {
      setReportServer('sent')
    }
    }, [server])
    const handleTextareaChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 250) {
          setFdb(inputValue);
        }
      };


      const handleTextareaChangeReport = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 250) {
          setReportMsg(inputValue);
        }
      };

      const handleSubmitReport = async(fdb, i) => {

       //console.log(fdb)

       setReportSend(prev => {
        const updatedPanels = [...prev];
        updatedPanels[i] = true;
        return updatedPanels;
        });
        const config = {
            method: 'POST',
            url: `${api}/server/report/fdb`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
            data: {
                content: fdb.content,
                authorId: fdb.userId,
                serverId: server.extra.id,
                date: Date.now(),
            }
          };
          let res = await axios(config)

          if(!res.data) return;


      }
      

      const copyLink = (e) => {
        e.innerHTML = "Copié"
        setTimeout(() => {
            e.innerHTML = "Copier le lien"
        }, 1000)
         navigator.clipboard.writeText(`https://${server.link}`)
      }

      const handleSubmitReportServer = async() => {
        setReportServer("typing")
       }


       const handleSubmitReportServerSend = async() => {
        setReportMsg("")
        setReportServer("sent")

        
         addServerIdToLocalStorage(server.extra.id);

         const config = {
             method: 'POST',
             url: `${api}/server/report`,
             headers: {
               'Content-Type': 'application/json', 
               'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
             },
             data: {
                 serverId: server.extra.id,
                 date: Date.now(),
                 reason: reportMsg
             }
           };
           let res = await axios(config)
           if(!res.data) return;
       }


      const handleSubmitFeedback = async() => {
        if(stars === 0) return setErrorMessage('Veuillez noter en cliquant sur le nombre d\'étoiles souhaitées.')
        setErrorMessage(null)
        setSendLoading(true)
        const config = {
            method: 'POST',
            url: `${api}/server/ratings/new`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
            data: {
                serverId: server.extra.id,
                content: fdb,
                rating: stars
            }
          };
          let res = await axios(config)
          setSendLoading(false)
          if(res.data.status === 202) {
            setServer(res.data.data)
            let userFdb = res.data.data.votes?.find(v => v.userId === user.id);
            if(userFdb) {
                setFdb(userFdb.content)
                setStars(userFdb.rating)
            }
          }
          if(res.data.status === 404) {
              if(res.data.data === "alr_fdb") {
                 setErrorMessage("Vous avez déjà poster un avis.")
              } else {
                window.location.reload()
              }
          }
      }

      const handleEditFeedback = async() => {
        if(stars === 0) return setErrorMessage('Veuillez noter en cliquant sur le nombre d\'étoiles souhaitées.')
        setErrorMessage(null)
        setSendLoading(true)
        const config = {
            method: 'POST',
            url: `${api}/server/ratings/edit`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
            data: {
                serverId: server.extra.id,
                content: fdb,
                rating: stars
            }
          };
          let res = await axios(config)
          setSendLoading(false)
          if(res.data.status === 202) {
            setServer(res.data.data)
            let userFdb = res.data.data.votes?.find(v => v.userId === user.id);
            if(userFdb) {
                setFdb(userFdb.content)
                setStars(userFdb.rating)
            }
          }
          if(res.data.status === 404) {
              if(res.data.data === "alr_fdb") {
                 setErrorMessage("Impossible de modifier votre avis.")
              } else {
                window.location.reload()
              }
          }
      }

      const handleDeleteFeedback = async() => {
        setErrorMessage(null)
        const config = {
            method: 'POST',
            url: `${api}/server/ratings/delete`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
            data: {
                serverId: server.extra.id
            }
          };
          let res = await axios(config)
          if(res.data.status === 202) {
            setServer(res.data.data)
                setFdb("")
                setStars(0)
          }
          if(res.data.status === 404) {
              if(res.data.data === "alr_fdb") {
                 setErrorMessage("Impossible de supprimer votre avis.")
              } else {
                window.location.reload()
              }
          }
      }



    const TopStars = () => {
        let votes = server.votes
        const totalRatings = server.votes.reduce((sum, vote) => sum + vote.rating, 0);
        const averageRating = totalRatings / votes.length;
        const roundedRating = Math.round(averageRating * 2) / 2;
        const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (roundedRating >= i) {
      stars.push(<TiStarFullOutline key={i} />);
    } else if (roundedRating > i - 1 && roundedRating < i) {
      stars.push(<TiStarHalfOutline key={i} />);
    } else {
      stars.push(<TiStarOutline key={i} />);
    }
  }

  return (
    <div>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
    }


    function formatDate(timestamp) {
        const now = Date.now();
        const diff = now - timestamp;
        const secondes = Math.floor(diff / 1000);
        const minutes = Math.floor(secondes / 60);
        const heures = Math.floor(minutes / 60);
        const jours = Math.floor(heures / 24);
        
        if (secondes < 60) {
          return `Il y'a ${secondes} seconde${secondes > 1 ? "s" : ""}`;
        } else if (minutes < 60) {
          return `Il y'a ${minutes} minute${minutes > 1 ? "s" : ""}`;
        } else if (heures < 24) {
          return `Il y'a ${heures} heure${heures > 1 ? "s" : ""}`;
        } else if (jours === 1) {
          return 'Hier';
        } else if (jours < 30) {
          return `Il y'a ${jours} jours`;
        } else {
          const date = new Date(timestamp);
          const annee = date.getFullYear();
          const mois = (date.getMonth() + 1).toString().padStart(2, '0');
          const jour = date.getDate().toString().padStart(2, '0');
          return `${annee}-${mois}-${jour}`;
        }
      }


    return (
      <div className="serverPage">
        {loading || !server ? (
          <Loading />
        ) : (
          <div className="serverApp">
            <div className="top">
              <div className="a">
                <div className="icon">
                  {server.extra?.iconURL ? (
                    <img src={server.extra.iconURL}  alt='' />
                  ) : null}
                </div>
                <div className="nfs">
                  <div className="name">{server.guildName}</div>
                  <div className="stats">
                    <div className="owner">{server.extra.owner}</div>
                  </div>
                  <div className="vote">
                    <div className="starsCount">
                      {server.votes ? (
                        <div className="stars">
                           <TopStars/>
                        </div>
                      ) : (
                        <div className="stars empty">
                          {[...Array(5)].map(() => (
                            <TiStarOutline />
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="voteCount">
                      ( {server.votes?.length || 0} )
                    </div>
                  </div>
                </div>
              </div>
              <div className="btns">
                <Link className="btn" to="#reviews">
                  <FaPen /> Avis
                </Link>
                <a
                  href={"https://" + server.link}
                  target="_blank"
                  rel="noreferrer"
                  className={"btn" + (server.link ? "" : " disabled")}
                >
                  Rejoindre
                </a>
                <div className={`shareDrop${shareOpen ? " open" : ""}`}>
                  <div
                    className="btn shr"
                    onClick={() => setShareOpen(!shareOpen)}
                  >
                    <RiShareFill />
                  </div>
                  <div className="drp">
                    <div href="/" className="scbtn" onClick={(e) => copyLink(e.target)}>
                      Copier le lien
                    </div>
                    {
                    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Report
                    }
                    
                      {!reportServer ?
                      <div href="/" className="scbtn" onClick={() => handleSubmitReportServer()}>
                      Signaler
                      </div>
                      : 
                      (reportServer === "sent" || (JSON.parse(localStorage.getItem('serverIds')) || []).find(s => s.id === server.extra.id)) ?
                      <div href="/" className="scbtn sent">
                      <BiCheck/> Envoyé
                      </div>
                      :
                      <div href="/" className="scbtn">
                      <div className="rptForm">
                        <textarea value={reportMsg} onChange={(e) => handleTextareaChangeReport(e)} name="report" placeholder='Raison du signalement...' id="report"></textarea>
                        <div className={`sendRpt${reportMsg.length > 1 ? "": " disabled"}`} onClick={() => {
                          if(reportMsg.length > 1) {
                            handleSubmitReportServerSend()
                          }
                        }}>
                          Envoyer
                        </div>
                      </div>
                       </div>
                      }
                   
                  </div>
                </div>
              </div>
            </div>

            
            <>
            {
            server.categorys ?
            <div className="cats">
            {server.categorys?.map(ct => {
                 return (
                          <div className="mCt">{catsOptions.find(c => c.value === ct).label}</div>
                         )
            })}
            </div>
             :
              null
            }
            </>
            <div className="description">
              {server.description || "No description"}
            </div>

            <div className="reviews">
              <h1 className='title'>Reviews</h1>

              <div className="rvwStats">
                <div className="lft">
                    <div className="vtsL">
                        {server.votes ?
                        server.votes.length
                        :
                        0
                        }
                     <h3>
                        Avis
                    </h3>
                    </div>
                </div>

                <div className="rgt">
                    <div className="stls">

                        {[...Array(5)].map((e, indexx) => {
                            let width = 0;
                            if(server.votes?.length > 0) {
                                let allVotes = server.votes.filter(v => v.rating === indexx+1)
                                width = Math.round(allVotes.length * 100 / server.votes.length)
                            }
                            return (
                                <div className="stt">
                                    <h4 className="count">{indexx+1}/5</h4>
                                <div className="bar">
                                    <div className="fill" style={{width: `${width}%`}}></div>
                                </div>
                                </div>
                            )
                        
                    
                        })}
                    </div>
                </div>
              </div>
              
              
                {
                    !server.votes?.find(v => v.userId === user.id) ?
                    ////////////////////// POST A NEW FEDBACK ( ONLY IF USER DIDNT )
                    <div id='vote' className={`rateForm${(user.authenticated && user.guilds?.find(g => g.id === id)) ? "": " disabled"}`}>
                    {
                        user.authenticated ?
                        null
                        :
                        <div className="adv">
                            Connectez-vous pour écrire un avis.
                        </div>
                    }
                    {
                        user.authenticated && !user.guilds?.find(g => g.id === id) ?
                        <div className="adv">
                            Vous n'êtes pas dans ce serveur.
                        </div>
                        :
                        null
                    }
                    <div className="rateStars">
                      {[...Array(5)].map((e, indexx) =>
                        indexx < stars ? (
                          <TiStarFullOutline
                            key={indexx}
                            className="full"
                            onClick={() => setStars(indexx + 1)}
                          />
                        ) : (
                          <TiStarOutline
                            key={indexx}
                            className="empty"
                            onClick={() => setStars(indexx + 1)}
                          />
                        )
                      )}
                    </div>
                    <textarea
                      name="feedback"
                      id="fdb"
                      placeholder="Laisser un avis..."
                      onChange={handleTextareaChange}
                    >{fdb}</textarea>
                    <div className="errorMessage">
                        {errorMessage ? errorMessage : null}
                    </div>
                    <div className="btns">
                    {
                        sendLoading ?
                        <div className="sendRate disabled"><BiLoaderAlt/></div>
                        :
                        <div className="sendRate" onClick={handleSubmitFeedback}>Envoyer</div>
                    }
                    </div>
                    
                    
                  </div>
                  :
                  //////////////////////////////////////////// EDIT THE USER FEEDBACK
                  <div  id='vote' className="rateForm">
                  <div className="rateStars">
                    {[...Array(5)].map((e, indexx) =>
                      indexx < stars ? (
                        <TiStarFullOutline
                          key={indexx}
                          className="full"
                          onClick={() => setStars(indexx + 1)}
                        />
                      ) : (
                        <TiStarOutline
                          key={indexx}
                          className="empty"
                          onClick={() => setStars(indexx + 1)}
                        />
                      )
                    )}
                  </div>
                  <textarea
                    name="feedback"
                    id="fdb"
                    placeholder="Modifier votre avis..."
                    onChange={handleTextareaChange}
                  >{fdb}</textarea>
                  <div className="errorMessage">
                      {errorMessage ? errorMessage : null}
                  </div>
                  <div className="btns">
                     {
                      sendLoading ?
                      <div className="sendRate disabled"><BiLoaderAlt/></div>
                      :
                      <div className="sendRate" onClick={handleEditFeedback}>Modifier</div>
                    }
                    <div className="sendRate dlt" onClick={handleDeleteFeedback}><MdDelete className='trsh'/></div>
                  </div>
                 
                  
                </div>
                }


            {
            ///////////////////// LIST OF FEEDBACKS
            }

            {
            server.votes?.length > 0 ?
               <div className="fdbList">
                {
                      server.votes.sort((a, b) => b.date - a.date).map((fdb, i) => {
                        let member = server.members?.find(e => e.id === fdb.userId);
                        return (
                        <div className="fdb" key={i}>
                            <div className="fbCt">
                                  <div className="fTp">
                                <div className="icon">
                                    {
                                        member?.iconURL ?
                                        <img src={member.iconURL}  alt=''/>
                                        :
                                        null
                                    }
                                </div>
                                <h1 className="name">
                                    {member?.username || "Unknown"}
                                </h1>
                            </div>
                            <div className="fdbStarsRate">
                            {[...Array(5)].map((e, indexx) =>
                      indexx < fdb.rating ? (
                        <TiStarFullOutline
                          key={indexx}
                          className="full"
                        />
                      ) : (
                        <TiStarOutline
                          key={indexx}
                          className="empty"
                        />
                      )
                    )}
                            </div>
                            <div className="content">
                            {fdb.content}
                            </div>
                            <div className="date">
                            {datePosts[i]} {fdb.edited ? <span className="edited">( Modifié )</span>: ""}
                            </div>
                            </div>

                            <div className={`rptPanel${reportPanelOpen[i] ?" open":""}`}>
                                <div className="label" onClick={() => {
                                  setReportPanel(prev => {
                                    const updatedPanels = [...prev];
                                    updatedPanels[i] = !updatedPanels[i];
                                    return updatedPanels;
                                });
                                }}>
                                <HiDotsVertical/>
                                </div>
                                <div className="drp">
                                    <div className="report" onClick={(e) => {
                                        handleSubmitReport(fdb, i)
                                    }}>{reportSend[i] ? <BiCheck/> : "Signaler"}</div>
                                </div>
                            </div>
                          
                        </div>                            
                        )

                    })
                }
               </div>
                :
                null
            }
            
            </div>
          </div>
        )}
      </div>
    );
}

export default Server