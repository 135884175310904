import { BiLogoDiscordAlt } from "react-icons/bi"

function NeedToLogin({handleLogin}) {
    return (
 <div className="needToLogin">
        <div className="txts">
            <h1>Veuillez vous connecter pour accéder à cette page.</h1>
            <div className="btn" onClick={() => handleLogin()}>
                <BiLogoDiscordAlt/> Se connecter
            </div>
        </div>
        </div>
    )
}
export default NeedToLogin