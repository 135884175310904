import { BiChevronDown, BiChevronUp, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { BsSortDown, BsSortNumericDown, BsSortNumericUp, BsSortUp } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import '../../Assets/Css/Components/Votes.css'
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from "react-icons/ti";

const Votes = ({api}) => {

    const [sortOpen, setSortOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [sort, setSort] = useState('a')
    const [badges, setBadges] = useState([])
    const [categorys, setCategorys] = useState([])

    const [servers, setServers] = useState([])

    async function loadData() {
        let res = await axios(`${api}/servers`)

        if(!res.data.data) return;

        setServers(res.data.data.sort((a, b) => b.bumpcount - a.bumpcount))
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    function calculateAverage(server) {
        const votes = server.votes?.length ? server.votes : [];
        const totalRatings = votes.reduce((sum, vote) => sum + vote.rating, 0);
        const averageRating = votes.length > 0 ? totalRatings / votes.length : 0;
        return Math.round(averageRating * 2) / 2;
      }

    const sortFunctions = {
        a: ((a, b) => (b.votes?.length || 0) - (a.votes?.length || 0)),
        b: ((a, b) => (a.votes?.length || 0) - (b.votes?.length || 0)),
        c: ((a, b) => {
            const averageA = calculateAverage(a);
            const averageB = calculateAverage(b);
            return averageB - averageA; // Tri décroissant
        }),
        d: ((a, b) => {
            const averageA = calculateAverage(a);
            const averageB = calculateAverage(b);
            return averageA - averageB; // Tri décroissant
        })
    }

    const sortOptions = [
        { value: "a", label: <><BsSortNumericUp/> Meilleurs avis</> },
        { value: "b", label: <><BsSortNumericDown/> Avis moyen</> },
        { value: "c", label: <><BsSortNumericUp/> Le + de votes</> },
        { value: "d", label: <><BsSortNumericDown/> Le - de votes</> },
    ];


    const badgesOptions = [
        {value: "premiums", label: "Premiums"},
        {value: "certified", label: "Certifiés"},
        {value: "bumpchannel", label: "Arrivée de bumps"},
        {value: "partners", label: "Partenaires"},
        {value: "firsthour", label: "Première heure"},
    ]
    
    function changeBadges(value) {
        let newArr = []
        if(badges.includes(value)) newArr = badges.filter(b => b !== value)
        else newArr = [...badges, value]
       setBadges(newArr)
    }

    const catsOptions = [
        { label: "Gaming", value: "gaming" },
        { label: "Publicitaire", value: "publicitaire" },
        { label: "Roleplay", value: "roleplay" },
        { label: "Communautaire", value: "communautaire" },
        { label: "Développement", value: "developpement" },
        { label: "Informatique", value: "informatique" },
        { label: "Graphisme", value: "graphisme" },
        { label: "Art", value: "art" },
        { label: "Musique", value: "musique" },
        { label: "Support", value: "support" },
        { label: "Réseaux", value: "reseaux" },
        { label: "Giveaway", value: "giveaway" },
        { label: "Animé", value: "anime" },
        { label: "Fun", value: "fun" },
        { label: "Chill", value: "chill" },
        { label: "Emoji", value: "emoji" },
        { label: "Nitro", value: "nitro" },
        { label: "Film", value: "film" },
        { label: "NSFW", value: "nsfw" },
        { label: "Sport", value: "sport" },
        { label: "Etudes", value: "etudes" }
      ];


    function changeCats(value) {
        let newArr = []
        if(categorys.includes(value)) newArr = categorys.filter(b => b !== value)
        else newArr = [...categorys, value]
       setCategorys(newArr)
    }


    function handleSearchBar(e) {
        let searchKey = e.value.replace(/[\s-]/g, '');
        let newServArr = []
        servers.forEach(srv => {
            let servName = srv.guildName.replace(/[\s-]/g, '');
            let servDesc = srv.description.replace(/[\s-]/g, '');
            if(!searchKey) {
                srv.hided = false;
            } else {
                if(servName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) || servDesc.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) {
                srv.hided = false
            } else {
                srv.hided = true
            }
            }
            newServArr.push(srv)
            setServers(newServArr)
        })
      
    }


    const TopStars = ({server}) => {
        let votes = server.votes?.length ? server.votes : []
        const totalRatings = votes.reduce((sum, vote) => sum + vote.rating, 0);
        const averageRating = totalRatings / votes.length;
        const roundedRating = Math.round(averageRating * 2) / 2;
        const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (roundedRating >= i) {
      stars.push(<TiStarFullOutline key={i} />);
    } else if (roundedRating > i - 1 && roundedRating < i) {
      stars.push(<TiStarHalfOutline key={i} />);
    } else {
      stars.push(<TiStarOutline  className="empty"  key={i} />);
    }
  }

  return (
    <div>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
    }

    return ( 
       <div className="bumpsApp">
        <div className="searchApp">
            <div className="searchBar">
                <input id="searchByName" spellCheck={false} type="text" onChange={(e) => { handleSearchBar(e.target)}} />
                <div className="srcBtn">
                <BiSearch/>
                </div>
            </div>
            <div className={`sortMenu${sortOpen?" open":""}`}>
                <div className="label" onClick={()=>setSortOpen(!sortOpen)}>
                <div className="txt">
                {sortOptions.find(o => o.value===sort).label}
                </div>
                <div className="btn">
                <BiChevronUp/>
                </div>
                </div>
                <div className="drop">
                    <ul> 
                        {sortOptions.map(o => {
                            return (
                                <li key={o.value} className={o.value === sort ? "active":""} onClick={() => {
                                    setSort(o.value)
                                    setSortOpen(!sortOpen)
                                    }}>
                                {o.label}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        <div className="filters">
            <div className="badges">
                <h1>Par badges :</h1>
                <div className="badgesList">
                    {
                        badgesOptions.map(b => {
                            return (
                                 <div key={b.value} className={`b${badges.includes(b.value)?' selected': ''}`} onClick={() => changeBadges(b.value)}>
                                <div className="lbl">
                                    {b.label}
                                </div>
                                <div className="hided">{b.label}</div>
                            <div className="icon"><BiX/></div>
                            </div> 
                            )
                          
                        })
                    }
              
                </div>
            </div>
            <div className="badges cats">
                <h1>Catégories :</h1>
                <div className="badgesList">
                    {
                        catsOptions.map(b => {
                            return (
                                 <div key={b.value} className={`b${categorys.includes(b.value)?' selected': ''}`} onClick={() => changeCats(b.value)}>
                                <div className="lbl">
                                    {b.label}
                                </div>
                                <div className="hided">{b.label}</div>
                            <div className="icon"><BiX/></div>
                            </div> 
                            )
                          
                        })
                    }
              
                </div>
            </div>
        </div>


        <div className="serversList">

            {
                loading ?
                <div className="loadingApp bumps">
                    <BiLoaderAlt />
                </div>
                :
                servers.sort(sortFunctions[sort]).map(srv => {
                    if(srv.hided) return;
                    if(badges.includes('premiums') && !srv.premium) return;
                    if(badges.includes('partners') && !srv.badge.partner) return;
                    if(badges.includes('bumpchannel') && !srv.badge.bumpchannel) return;

                    if(!categorys.every(ct => (srv.categorys || []).includes(ct))) return;
                    return (
                        <div className="server" id={srv.guildName} key={srv.guildID}>
                            <Link className="srvLink" to={"/server/"+srv.guildID}>
                            <div className="top">
                                <div className="icon">
                                    {srv.server?.iconURL?
                                    <img src={srv.server?.iconURL} alt='' />
                                    :
                                    null}
                                </div>
                                <div className="txt">
                                    <div className="name">
                                    {srv.guildName}
                                    </div>  
                                         <div className="starRating">
                               <TopStars server={srv}/>
                               </div>
                                    </div>
                               </div>
                             
                          
                            <div className="middle">
                                <div className="cats">
                                    {
                                    srv.categorys ?
                                       srv.categorys.map(ct => {
                                        return (
                                            <div className="mCt">{catsOptions.find(c => c.value === ct).label}</div>
                                        )
                                       })
                                       :
                                       null
                                    }
                                </div>
                                <div className="description">
                                    {srv.description}
                                </div>
                            </div>
                            </Link>
                            <div className="bottom">
                                <a href={"https://"+srv.link} rel="noreferrer" target="_blank" className={"join btn"+(srv.link?"":" disabled")}>
                                    Rejoindre
                                </a>
                                
                                
                            <Link className="btn" to={"/server/"+srv.guildID+"?scrollTo=vote"}>
                                Voter 
                            </Link>
                            </div>
                        </div>
                    )
                })
            }

        </div>
       </div>
     );
}
 
export default Votes;