import './Assets/Css/Main.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import Home from "./Components/Pages/Home"
import Navbar from './Components/Components/Navbar';
import Commands from './Components/Pages/Commands';
import Bumps from './Components/Pages/Bumps';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Loading from './Components/Components/Loading';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import Server from './Components/Pages/Server';
import Votes from './Components/Pages/Votes';
import Ranking from './Components/Pages/Ranking';
import Status from './Components/Pages/Status';
import Partners from './Components/Pages/Partners';
import Codes from './Components/Pages/Codes';
import CodeInfos from './Components/Pages/CodeInfos';


const api = "http://88.198.66.157:27019"


function App() {

  const [user, setUser] = useState({authenticated: false});
const [loading, setLoading] = useState(true);

function handleLogin() {
  var left = (window.screen.width/2)-(500/2);
  var top = (window.screen.height/2)-(750/2);
  const authWindow = window.open(api+'/login', 'zoboLogin', `tabbar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, titlebar=no, scrollbars=no, zoom=80 ,resizable=no, width=500, height=700, left=${left}, top=${top}`)
  
  window.addEventListener('message', receiveMessage);

  function receiveMessage(event) {
    console.log(event)
    if (event.origin !== api) return;
    if (event.data.type === 'token') {
      localStorage.setItem('jwt', event.data.token);
    }
  }
  
  const timer = setInterval(() => {
    if (authWindow.closed) {
      clearInterval(timer);
      handleCallback();
    }
  }, 500);
}


async function handleCallback() {
  //console.log('Execute handleCallback')
  try {
    const config = {
      method: 'POST',
      url: `${api}/user`,
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
      }
    };
    let res = await axios(config)
    console.log(res.data.user)
    if(res.data.user) {
      setUser(res.data.user)
      setLoading(false);
    }
  } catch(e) {
    console.log(e)
    setLoading(false);
  }
}

//console.log(user)


useEffect(() => {
handleCallback()
}, [])

function handleLogout() {
  localStorage.removeItem('jwt'); // Supprime le JWT du stockage local
  setUser({ authenticated: false });
}



  const NavbarComponent = () => {
    return (
      <Navbar user={user} setUser={setUser} api={api} handleLogin={handleLogin} handleCallbacK={handleCallback} handleLogout={handleLogout}/>
    )
  }

  const RedirectComponent = () => {
    useEffect(() => {
      window.location.href = 'https://discord.gg/PUYWmkGWyk';
    }, []);
  
    return null;
  };

  

  return (

    <div className='mainApp'>
     {
      loading ?
      <Loading/>
      :
          <BrowserRouter>
    <Routes>
      <Route path="/" element={<><NavbarComponent /><Home  api={api} /></>} />
      <Route path="/commands" element={<><NavbarComponent /><Commands api={api} /></>} />
      <Route path="/commands" element={<><NavbarComponent /><Commands api={api} /></>} />
      <Route path="/bumps" element={<><NavbarComponent /><Bumps api={api} /></>} />
      <Route path="/votes" element={<><NavbarComponent /><Votes api={api} /></>} />
      <Route path="/leaderboard" element={<><NavbarComponent /><Ranking api={api} /></>} />
      <Route path="/status" element={<><NavbarComponent /><Status api={api} /></>} />
      <Route path="/partners" element={<><NavbarComponent /><Partners api={api} /></>} />
      <Route path="/codes" element={<><NavbarComponent /><Codes api={api} /></>} />
      <Route path="/code/:id" element={<><NavbarComponent /><CodeInfos api={api} /></>} />
      <Route path="/dashboard" element={<><Dashboard user={user} setUser={setUser} handleLogin={handleLogin} handleLogout={handleLogout} api={api} /></>} />
      <Route path="/dashboard/:id" element={<><Dashboard user={user} setUser={setUser} handleLogin={handleLogin} handleLogout={handleLogout} api={api} /></>} />
      <Route path="/dashboard/:id/:category" element={<><Dashboard key={"withoutcode"} user={user} setUser={setUser} handleLogin={handleLogin} handleLogout={handleLogout} api={api} /></>} />
      <Route path="/dashboard/:id/:category/:codeId" element={<><Dashboard key={"withcode"} user={user} setUser={setUser} handleLogin={handleLogin} handleLogout={handleLogout} api={api} /></>} />
      <Route path="/server/:id" element={<><NavbarComponent /><Server user={user} handleLogin={handleLogin} handleLogout={handleLogout} api={api} /></>} />
      <Route path='/zcode' element={<RedirectComponent />} />

    </Routes>
  </BrowserRouter>
    }
    </div>


  );
}

export default App;
