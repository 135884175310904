import { FaArrowRight, FaArrowLeft, FaArrowUp, FaDiscord, FaHourglassEnd, FaRegCopyright, FaWrench, FaYoutube } from 'react-icons/fa'
import '../../Assets/Css/Components/Home.css'
import {ReactComponent as Star} from "../../Assets/Icons/star.svg"
import { MdOutlineExplore } from 'react-icons/md'
import { FaRankingStar, FaSquarePollVertical } from 'react-icons/fa6'
import { RiShieldKeyholeFill, RiZzzFill } from 'react-icons/ri'
import { useEffect, useRef, useState } from 'react'
import { BiSolidCopyright } from 'react-icons/bi'



const Home =  ({}) => {

  const counterRef = useRef(null);
  const Counter = ({ maxValue, speedInMs, interval }) => {
    const [counter, setCounter] = useState(0);


    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // Démarrer le compteur lorsque la div entre dans la vue
            const intervalId = setInterval(() => {
              setCounter((prevCounter) => (prevCounter < maxValue ? prevCounter + interval : prevCounter));
            }, speedInMs);
  
            // Nettoie l'intervalle lorsque la div n'est plus dans la vue
            return () => clearInterval(intervalId);
          }
        },
        { threshold: 0.5 } // Vous pouvez ajuster le seuil selon vos besoins
      );
  
      // Attacher l'observateur à la référence de la div
      if (counterRef.current) {
        observer.observe(counterRef.current);
      }
  
      // Nettoie l'observateur lorsque le composant est démonté
      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, [maxValue, speedInMs, interval]);
  
    return counter
  };



   const [images, setImages] = useState(["https://media.discordapp.net/attachments/1078661751215763536/1197878901318369320/image.png?ex=65c61929&is=65b3a429&hm=c896e77a1b456c294d0b3bcd979c2e8d1788e9adec196d0c43017da5bb71375f&", "https://singlecolorimage.com/get/000000/400x100", "https://singlecolorimage.com/get/33fd8f/400x100", "https://singlecolorimage.com/get/000000/400x100", "https://singlecolorimage.com/get/33fd8f/400x100"])

   const [imagesSetted, setImagesSetted] = useState(false)
   const containerRef = useRef(null);
   const [count, setCount] = useState(3);


   
   useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 850) {
        setCount(1);
      } else if (screenWidth < 1300) {
        setCount(2);
      } else {
        setCount(3); // ou la valeur initiale que vous souhaitez pour les écrans plus grands que 1200px
      }
    };

    handleResize()

    // Ajoutez un écouteur pour les changements de taille d'écran
    window.addEventListener('resize', handleResize);

    // Nettoyez l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



    return (
      <div className="homePage">
       
        <div className="header">
        

        <div className="ctnt">
          <div className="txts">
          <h3>By <a href="https://discord.gg/zcode">Z-Code</a></h3>
          <h1>Zobo Bot</h1>
          <Star className="a"/>
          <h2>Prêt à faire passer votre serveur au niveau supérieur ?</h2>
          <div className="hdBtns">
            
          <Star className="b"/>
            <div className="hdb">
              <a target='_blank' href="https://discord.com/oauth2/authorize?client_id=988445343685414933&scope=bot%20applications.commands&permissions=4228377727">Ajouter le</a>
            </div>
            <div className="hdb">
              <a href="javascript:void(0)" onClick={() => {
                document.querySelector('.linksContainer').scrollIntoView({behavior: "smooth"})
              }}>Fonctionnalités</a>
            </div>
          </div>
          </div>
        </div>
        
        </div>



        <div className="linksContainer">
          <a href="https://discord.com/invite/vYxCMt37NA" className="linkct" target='_blank'>
          <FaDiscord />
          </a>
          <a href="https://www.youtube.com/c/nettleytbbdfd?app=desktop" className="linkct" target='_blank'>
          <FaYoutube />
          </a>
          <a href="https://top.gg/bot/988445343685414933" className="linkct" target='_blank'>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
stroke="none">
<path d="M2 1768 l3 -313 26 -50 c29 -56 91 -109 147 -125 22 -6 160 -10 335
-10 l297 0 0 405 0 405 -406 0 -405 0 3 -312z"/>
<path d="M940 1269 l0 -811 223 4 222 3 77 37 c86 41 161 105 207 177 60 93
65 117 70 361 l6 225 220 5 c192 5 227 9 272 27 170 68 281 198 312 368 6 32
11 139 11 237 l0 178 -810 0 -810 0 0 -811z"/>
</g>
</svg>

          </a>
        </div>
        



        <div className="imagesSlide">
          <div className="prevArr">
            <div className="pvBtnIc" onClick={() => {
               let newArr = [...images]
               let element = newArr.pop()
               newArr.unshift(element)
               setImages(newArr)
            }}>

            <FaArrowLeft/>
            </div>
          </div>
          <div className="sld" ref={containerRef}>

            {
              images.slice(0, count).map(img => {
                return (
                  <div className={"sldImg"}>
                    <img src={img} alt="" />
                  </div>
                )
              })
            }

          </div>
          <div className="nextArr">
            <div className="pvBtnIc" onClick={() => {
               let newArr = [...images]
               let element = newArr.shift()
               newArr.push(element)
               setImages(newArr)
            }}>

            <FaArrowRight/>
            </div>
          </div>
        </div>




















        <div className="features" id="features">
          <div className="feature">
            <div className="icon">
            <MdOutlineExplore />
            </div>
            <h3>
            Zobo possède un système de bumps facile d'utilisation pour propulser votre serveur.
            </h3>
          </div>
          <div className="feature">
            <div className="icon">
            <FaRankingStar />
            </div>
            <h3>
            Avec un système de rôle pour les membres en fonction de leur nombre de bumps pour plus de concurrence.
            </h3>
          </div>
          <div className="feature">
            <div className="icon">
            <FaWrench />
            </div>
            <h3>
            Zobo facilite le travail des modérateurs en gérant le serveur via diverses commandes uniques.
            </h3>
          </div>
          <div className="feature">
            <div className="icon">
            <FaHourglassEnd />
            </div>
            <h3>
            Zobo vous propose un système d'afk qui permet de modifier votre pseudo et d'avoir un "ne pas déranger" plus visible
            </h3>
          </div>
        </div>



        <div className="botStats" ref={counterRef}>
          <div className="sts">
            <h1><Counter maxValue={Math.round(497 / 10) * 10} interval={2} speedInMs={0}/><span>+</span></h1>
            <h3>Serveurs</h3>
          </div>
          <div className="sts">
          <h1><Counter maxValue={Math.round(1264 / 10) * 10} interval={5} speedInMs={0}/><span>+</span></h1>
            <h3>Utilisateurs</h3>
          </div>
          <div className="sts">
          <h1><Counter maxValue={Math.round(224 / 10) * 10} interval={2} speedInMs={0}/><span>+</span></h1>
            <h3>Bumps</h3>
          </div>
          <div className="sts">
          <h1><Counter maxValue={Math.round(26 / 10) * 10} interval={2} speedInMs={(20) < 100 ? 100 : 0}/><span>+</span></h1>
            <h3>Votes</h3>
          </div>
        </div>


        <div className="footerApp">
          <div className="scrollToTop" onClick={() => {
            let element = document.querySelector('.navbarApp')
             element.scrollIntoView({ behavior: 'smooth' });
          }}>
          <FaArrowUp />
          </div>
          <h1 className="cprt">
            Zobo Bot, By Z-Code, 2024 © 
          </h1>
        </div>
        {//<div style={{height: "500px"}}></div>
        }
      </div>
    )
}

export default Home
