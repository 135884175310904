import { Link, useParams } from 'react-router-dom'
import '../../../Assets/Css/Components/Dash/Dashboard.css'
import NeedToLogin from '../../Components/NeedToLogin'
import { BiSolidDashboard, BiSolidMessageSquareDetail, BiSolidRocket } from "react-icons/bi";
import DashNavbar from './Components/Navbar'
import ServersSelect from './Components/ServersSelect'
import { FaUser } from "react-icons/fa6";
import { useEffect, useState } from 'react';
import axios from "axios"
import DashUserView from './Views/Users';
import DashUserViewAdmin from './Views/Admin';
import DashUserViewCodes from './Views/Codes';
import DashGeneralView from './Views/General';
import DashBumpView from './Views/Bumps';
import SideMenu from './Components/SideMenu';
import DashBumpRoleView from './Views/RoleBumps';
import DashChannelsView from './Views/Channels';


const NeedToInvite = ({handleAddBot, guild}) => {
  return (
    <div className="needToLogin">
    <div className="txts">
        <h1>Zobo n'est pas sur ce serveur.</h1>
        <div className="btn" onClick={() => handleAddBot(guild.id)}>
          Ajouter le bot
        </div>
    </div>
    </div>
  )
}

const NotFoundGuild = () => {
  return (
    <div className="needToLogin">
    <div className="txts">
        <h1>Serveur introuvable.</h1>
    </div>
    </div>      
  )
}


   const ServerRenderView = ({user, api, setUser, guild, category}) => {
       if(!category) return (<DashGeneralView api={api} user={user} setUser={user} guild={guild}/>)
       if(category === "bump") return (<DashBumpView api={api} user={user} setUser={user} guild={guild}/>)
       if(category === "rolebump") return (<DashBumpRoleView api={api} user={user} setUser={user} guild={guild}/>)
       if(category === "channels") return (<DashChannelsView api={api} user={user} setUser={user} guild={guild}/>)
       else return ("settings")
   }

   const DashboardContent = ({guild, category, api, user, id, handleAddBot, setUser}) => {
      return (
      <div className="dashboardContent">
        {
          guild ?
          (guild === "notfound" ?
            <NotFoundGuild/>
            :
            (guild.bot ?
              <ServerRenderView user={user} api={api} setUser={user} guild={guild} category={category}/>
              :
              <NeedToInvite handleAddBot={handleAddBot} guild={guild}/>
            )
          )
          :
          (
           id === "admin" ?
              category === "dash" ?
            <DashUserViewAdmin api={api} user={user} setUser={setUser}/>
            :
            <DashUserViewCodes api={api} user={user} setUser={setUser}/>
            :
            <DashUserView api={api} user={user} setUser={setUser}/>
          )
        }
      </div>
      )
   } 


const Dashboard =  ({api, user, handleLogin, handleLogout, handleAddBot, setUser}) => {

  const {id, category} = useParams()
  const [guilds, setGuilds] = useState([])
  const [open, setOpen] = useState(false)



  function handleAddBot(id) {
    var left = (window.screen.width/2)-(500/2);
    var top = (window.screen.height/2)-(750/2);
    const authWindow = window.open(`https://discord.com/api/oauth2/authorize?client_id=911678392846397454&permissions=8&guild_id=${id}&disable_guild_select=true&redirect_uri=${api}/newGuilds&response_type=code&scope=bot%20identify%20guilds%20applications.commands`, 'anekoAddBot', `tabbar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, titlebar=no, scrollbars=no, zoom=80 ,resizable=no, width=500, height=700, left=${left}, top=${top}`)
    const timer = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(timer);
        handleAddBotCallBack();
      }
    }, 500);
}

async function handleAddBotCallBack() {
    try {
      const config = {
        method: 'POST',
        url: `${api}/checkGuilds`,
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
        }
      };
      let res = await axios(config)
        setGuilds(res.data.guilds)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    handleAddBotCallBack()
  }, [])

  let guild = guilds ? id ? id !== "admin" ? (guilds.find(g => g.id === id) || "notfound") : null : null : null




     

    return (
      <div className="dashboardApp">

      
        
        {
            user.authenticated ?
           <>
             <DashNavbar open={open} setOpen={setOpen} api={api} user={user} handleLogin={handleLogin} handleLogout={handleLogout}/>
             <SideMenu guilds={guilds} api={api} user={user} id={id} open={open} setOpen={setOpen} guild={guild} category={category} />
             <DashboardContent id={id} handleAddBot={handleAddBot} guild={guild} category={category} api={api} user={user} setUser={setUser} />
          

        {/*
          guild ?
          (
            guild === "notfound" ?
            /////////////////////////// Si serveur non trouvé
            <>
            <SideMenu guilds={guilds} api={api} user={user} id={id} open={open} setOpen={setOpen} guild={guild} category={category} />
           <div className="dashboardContent">
           
           </div>
           </>
            :
            //////////////////////////// Menu Serveur
            (
              guild.bot ?
           //////////////////////////// Si bot est sur le seveur
           <>
           <SideMenu guilds={guilds} api={api} user={user} id={id} open={open} setOpen={setOpen} guild={guild} category={category} />
           <div className="dashboardContent">
            <ServerRenderView/>
           </div>
           </>
           :
           //////////////////////////// Si bot N'EST PAS sur le seveur
           <>
           <SideMenu guilds={guilds} api={api} user={user} id={id} open={open} setOpen={setOpen} guild={guild} category={category} />
           <div className="dashboardContent">
           <NeedToInvite/>
           </div>
           </>
            )
      
          )
          :
          //////////////////////////// Menu Utilisateur
          <>
          <SideMenu guilds={guilds} api={api} user={user} id={id} open={open} setOpen={setOpen} guild={guild} category={category} />
        <div className="dashboardContent">
                <DashUserView api={api} user={user} setUser={setUser}/>
        </div>
        </>
        */} 
        </>
            :
            <NeedToLogin handleLogin={handleLogin}/>
        }
       
      </div>
    )
}

export default Dashboard