import { useEffect, useRef, useState } from 'react';
import '../../../../Assets/Css/Components/Dash/Views/Codes.css'
import axios from "axios"
import { FaDotCircle, FaSearch } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';

const DashUserCode =  ({api, user, setUser}) => {

    const { codeId } = useParams()
    const [sentLoading, setSendLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [editedCode , setEditedCode] = useState(null)
    const [disabledBtn, setDisabledBtn] = useState(true)
    const inpName = useRef();
    const inpDesc = useRef();
    const inpPsw = useRef();
    const inpCode = useRef();
    const inpLink = useRef();
    const inpImage = useRef();

    const [codes, setCodes] = useState([{id: "a"}])
    const navigate = useNavigate();



    async function loadUserData() {
        try {
          const config = {
            method: 'POST',
            url: `${api}/user`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
       //   console.log(res.data)
          if(res.data.user) {
            if(!res.data.user.isAdmin || !res.data.user.isDev) return window.location.href = "/dashboard"
            setUser(res.data.user)
          }
        } catch(e) {
            return;
        }
      }


      async function loadData() {

        const config = {
            method: 'GET',
            url: `${api}/codes`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            }
          };
          let res = await axios(config)
          if(res.data.data) {
            console.log(res.data.data)
            setCodes(res.data.data.sort((a, b) => b.date - a.date))
          }
       
      }

  
      useEffect(() =>{
        loadData()
        loadUserData()
        //console.log(codeId)
       
      }, [])


      async function checkValues() {
        let bsCode = codes.find(cd => cd.id === codeId)
        //console.log(bsCode)
        if(!bsCode) return setDisabledBtn(false)
        let base = JSON.stringify({name: bsCode.name, description: bsCode.description, code:bsCode.code, password: bsCode.password, link: bsCode.link, image: bsCode.image})
        let current = JSON.stringify({name: inpName.current.value, description: inpDesc.current.value, code: inpCode.current.value, password: inpPsw.current.value, link: inpLink.current.value, image: inpImage.current.value})
        if(base === current) return setDisabledBtn(true)
        else return setDisabledBtn(false)
      }

      async function handleCodeCreate() {
        let codeObj = { name: inpName.current.value, description: inpDesc.current.value, link: inpLink.current.value, code: inpCode.current.value, password: inpPsw.current.value, image: inpImage.current.value }

        function isObjectEmpty(obj) {
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === "") {
                    return {res: true, nm: `${key}`}; // L'objet n'est pas vide, car une valeur est une chaîne vide
                }
            }
            return {res: false, nm: null}; // Aucune valeur n'est une chaîne vide, l'objet est considéré comme vide
        }

        if(isObjectEmpty(codeObj).res) return alert(`Veuillez entrer une valeur dans : ${isObjectEmpty(codeObj).nm}`)

        setSendLoading(true)
        const config = {
            method: 'POST',
            url: `${api}/codes/${codeId && codes.find(cd => cd.id === codeId) ? `edit/${codeId}` : `new`}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
            data: {
               ...codeObj
            }
          };
          try {
          let res = await axios(config)

          console.log(res)
          if(res.data.data) {

          [inpName, inpDesc, inpCode, inpPsw, inpLink, inpImage].forEach(inp => {
            return inp.current.value = ""
          })
          setCodes(res.data.data)
          }


          navigate('/dashboard/admin/code');
          setSendLoading(false)


          } catch(e) {
            return setSendLoading(false)
          }
      }

      async function handleCodeDelete() {
        setDeleteLoading(true)
        const config = {
            method: 'POST',
            url: `${api}/codes/delete/${codeId}`,
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${localStorage.getItem('jwt')}` // Ajout de l'Authorization header
            },
          };
          try {
          let res = await axios(config)

          console.log(res)
          if(res.data.data) {
           [inpName, inpDesc, inpCode, inpPsw, inpLink, inpImage].forEach(inp => {
                return inp.current.value = ""
          })
         
          navigate('/dashboard/admin/code');
          setCodes(res.data.data)
          }


          setDeleteLoading(false)
      } catch(e) {
        setDeleteLoading(false)
      }
    }

    



//////////////////////////// DATES /////////////////////////////////////
function formatDate(timestamp) {
    const now = Date.now();
    const diff = now - timestamp;
    const secondes = Math.floor(diff / 1000);
    const minutes = Math.floor(secondes / 60);
    const heures = Math.floor(minutes / 60);
    const jours = Math.floor(heures / 24);
    
    if (secondes < 60) {
      return `Il y'a ${secondes} seconde${secondes > 1 ? "s" : ""}`;
    } else if (minutes < 60) {
      return `Il y'a ${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else if (heures < 24) {
      return `Il y'a ${heures} heure${heures > 1 ? "s" : ""}`;
    } else if (jours === 1) {
      return 'Hier';
    } else if (jours < 30) {
      return `Il y'a ${jours} jours`;
    } else {
      const date = new Date(timestamp);
      const annee = date.getFullYear();
      const mois = (date.getMonth() + 1).toString().padStart(2, '0');
      const jour = date.getDate().toString().padStart(2, '0');
      return `${annee}-${mois}-${jour}`;
    }
  }
    const [dates, setDates] = useState([])
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Met à jour la date pour chaque élément dans server.votes
            setDates(prevDates => (
                codes.map(fdb => formatDate(fdb.date))
            ));
        }, 1000);
    
        // Nettoie l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [codes]);

    return (
        
        <div className="adminCodesSets">

          <div className="codeEditor">
            <h1>{codeId && codes.find(cd => cd.id === codeId) ? "Modifier" : "Créer"} un code</h1>

          <div className="inptsFlex">
            <div className="editorInp">
                <input type="text" ref={inpName} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.name || ""}  placeholder='Nom' />
            </div>
            <div className="editorInp">
                <input type="text" ref={inpLink} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.link || ""} placeholder='Lien de la vidéo' />
            </div>
            <div className="editorInp">
                <textarea name="description" ref={inpDesc} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.description || ""} id="description" placeholder='Description'></textarea>
            </div>
            <div className="editorInp">
                <textarea name="code" ref={inpCode} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.code || ""} id="code" className='codeTxt' placeholder='Code'></textarea>
            </div>
            <div className="editorInp">
                <input type="text" ref={inpPsw} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.password || ""}  placeholder='Mot de passe' />
            </div>

            <div className="editorInp">
                <input type="text" ref={inpImage} onChange={() => checkValues()} defaultValue={codes.find(cd => cd.id === codeId)?.image || ""}  placeholder="Lien de l'image" />
            </div>

            
            <div className="editorInp btnsList">
                <div className="btn"
                id={
                    disabledBtn 
                    ?
                    "disabled"
                    :
                    ""
                }
                onClick={() => {
                    handleCodeCreate()
                }}>
                    {
                        sentLoading ?
                        <BiLoaderAlt/>
                        :
                        codes.find(cd => cd.id === codeId) ? 
                        "Modifier" : "Créer"
                    }
                </div>
                {
                    codes.find(cd => cd.id === codeId) ? 
                    <div className="btn"
                    onClick={() => {
                        handleCodeDelete()
                    }}>
                        {
                            deleteLoading ?
                            <BiLoaderAlt/>
                            :
                            "Supprimer"
                        }
                    </div>
                    :
                    null
                }
            </div>
            </div>
          </div>


          <div className="codesList">
            <h1>Liste des codes</h1>
            <div className="codeSlct">
                {
                    codes.map((cd, i) => {
                        return (
                            <div className="codeMapped">
                                <div className="tps">
                                     <h1>{cd.name}</h1>
                                <p>( {dates[i] || "..."} )</p>
                                </div>
                               
                                <Link to={"/dashboard/admin/code/"+cd.id}>Modifier</Link>
                            </div>
                        )
                    })
                }
            </div>
          </div>

      </div>
    )
}

export default DashUserCode